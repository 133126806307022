.wrapper {
  text-align: right;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    text-align: left;
  }
}

.footer {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;
}

.result {
  margin-right: 24px;
  font-weight: 700;
}

.button {
  background: var(--orange-color);
  color: #fff;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: var(--night-color);
    color: #fff;
  }
}
