.head {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.inputWrapper {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--main-font-color);
  border-radius: 10px;
  margin-right: 24px;
  font-family: 'PF BeauSans Pro';
  max-width: 200px;
  font-size: 16px;
  background: transparent;
  border: 1px dotted var(--main-font-color);
  padding: 0px 12px;
  border-radius: 5px;
  outline: none;
  color: inherit;

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }

  @media (max-width: 700px) {
    min-width: calc(100% - 24px);
  }
}

.icon {
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.input {
  border: none;
  background: transparent;
  padding: 7px 5px;
  outline: none;
  color: inherit;
  font-family: 'PF BeauSans Pro' !important;
  font-size: 16px;
  color: var(--main-font-color) !important;

  &:hover {
    color: var(--main-font-color) !important;
  }

  &::placeholder {
    color: var(--main-font-color);
  }

  @media (max-width: 700px) {
    width: 100%;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 420px) {
    margin-right: 38px;

    & > * {
      flex: 0 0 100%;
    }
  }

  @media (max-width: 850px) and (min-width: 700px) {
    margin-bottom: 12px;
  }
}

.category {
  color: var(--main-font-color);
  background: var(--balance-card-background-st);
  border: 0;
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: var(--orange-color);
    color: #fff;
  }

  @media (min-width: 500px) {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }

  @media (max-width: 500px) and (min-width: 420px) {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  @media (max-width: 420px) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 850px) {
    margin-bottom: 12px;
  }
}

.category.active {
  background: var(--orange-color);
  color: #fff;
}
