.icon {
  width: 20px;
  height: 20px;

  @media (max-width: 992px) {
    width: 34px;
    height: 34px;
  }
}

.cards {
  width: 100% !important;
}

.cross {
  width: 30px;
}

.hovered:hover {
  fill: green !important;

  & > svg {
    fill: green !important;
  }
}

.search {
  width: 19px;
  fill: var(--main-font-color);
}

.callSvg {
  cursor: pointer;
}

.callSvg:hover {
  fill: green;
}

.numberOne {
  position: absolute;
  color: red;
  padding-left: 6px;
}

.cls1 {
  fill: #fff;
}