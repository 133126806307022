.styles {
  width: 100%;
}

.wrapNavigation {
  @media (max-width: 1050px) and (min-width: 960px) {
    display: block;
  }

  @media (min-width: 1050px) {
    display: none;
  }
}

.swiperContainer {
  position: relative;
  width: 100%;
  margin: 0 auto;
  font-size: 26px;
  font-family: 'PF BeauSans Pro';

  @media (max-width: 1050px) {
    width: 95%;
  }
}

.swiperSlide {
  flex: 1 0 auto;
  min-width: 190px;
  &.active {
    padding-left: 1px;
  }
}

.btn {
  padding: 6px 10px 7px;
  background: var(--orange-color);
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'PF BeauSans Pro';
  cursor: pointer;
  border: none;
  font-size: 16px;
  transition: all 0.3s ease;
  height: 100%;
  margin-left: 10px;

  &:hover {
    background: var(--night-color);
    color: #fff;
  }
}

.inlineSwiper {
  display: flex;
  height: 90px;
}

.card {
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  border: 1px solid var(--border-default);
  background: linear-gradient(
    61deg,
    var(--balance-card-background-st),
    var(--balance-card-background-end)
  );
}

.name,
.cost {
  color: var(--main-font-color);
}

.name {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 0;

  &.active {
    font-size: 17.01px;
  }
}

.cost {
  font-family: 'PF BeauSans Pro Bold';
  font-style: normal;
  letter-spacing: -0.7px;
  font-weight: 700;
  font-size: 25.89px;
  line-height: 31px;
  margin: 0;

  span {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
  }
}

.icon {
  background: var(--blue-color);
  padding: 10px 5px;
  display: block;
  width: 46px;
  height: 38px;
  border-radius: 10px;
  margin-left: 13px;
  text-align: center;

  & > img {
    width: 100%;
    height: 100%;
  }
}
