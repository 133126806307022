.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 24px 0;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 0 26px;
  }

  @media (max-width: 450px) {
    margin-top: 0;
  }
}

.cross {
  display: flex;
  align-items: center;
}

.preWrap {
  width: 60%;
  display: flex;

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.addCity {
  display: flex;
  align-items: center;

  & > p {
    transition: all 0.3s ease;
    padding: 2px 6px;
    border-radius: 10px;
  }

  & > p:hover {
    text-decoration : underline;
  }
}

.chooseCity {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  & p {
    margin-left: 5px;
  }
}

.cities {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 0;

  & > * {
    cursor: pointer;
    margin: 5px 5px 0;

    &:hover .minus {
      color: var(--background-gradient-st);
      background-color: var(--main-font-color);
    }
  }

  @media (max-width: 450px) {
    margin-left: 0;

    &.active {
      margin-top: 24px;
    }
  }
}

.minus {
  border: 1px solid var(--main-font-color);
  padding: 0 6px;
  margin-right: 7px;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.emergency {
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    margin-top: 24px;
  }
}

.text {
  margin-right: 24px;
  color: var(--main-font-color);

  &:hover {
    text-decoration: underline;
  }
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}

.tooltip {
  background: none;
}
