.mainPart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 40px;

  & > *:first-child {
    flex: 0 0 calc(20.127% - 24px);
    margin-top: -24px;
  }

  & > *:last-child {
    flex: 0 0 79.5%;
    padding-left: 20px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;

    @media (max-width: 992px) {
      flex: 0 0 100%;
    }
  }
}

.minimalDesign {
  & > *:first-child {
    flex: 0 0 24px !important;
  }

  & > *:last-child {
    flex: 0 0 93% !important;
  }
}

.flex {
  color: var(--main-font-color);
  padding: 24px;
}
