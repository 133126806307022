.checkbox {
  position: absolute;
  opacity: 0;
  z-index: 10;
  width: 50px;
  height: 20px;
  margin: 2px 0 0 0;
  cursor: pointer;
}

.newCheckbox {
  width: 40px;
  height: 20px;
  margin: 2px 0 0 0;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease;

  &::before {
    content: '';
    background: var(--blue-color);
    position: absolute;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    top: 0;
    left: 0;
    opacity: 0.1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--blue-color);
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &.active {
    &::before {
      background: var(--blue-color);
    }

    &::after {
      transform: translateX(21px);
    }
  }

  &.light.disabled {
    &::before {
      background: #e5e5e5;
      opacity: 0.8;
    }

    &::after {
      background: #e5e5e5;
      filter: contrast(0.5);
    }
  }

  &.dark.disabled {
    &::before {
      background: #e5e5e5;
      opacity: 0.05;
    }

    &::after {
      background: #e5e5e5;
      filter: contrast(0.5);
    }
  }
}

.flex {
  display: flex;
}
