.mainPart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 40px;

    & > *:first-child {
        flex: 0 0 calc(20.127% - 24px);
        margin-top: -24px;
    }

    & > *:last-child {
        flex: 0 0 79.5%;
        padding-left: 20px;
        border: 1px solid var(--border-default);
        box-sizing: border-box;
        border-radius: 20px;

        @media (max-width: 992px) {
            flex: 0 0 100%;
        }
    }
}

.bigDesign {
    padding: 20px;
    margin-left: 30px;
}

.minimalDesign {
    & > *:first-child {
        flex: 0 0 24px !important;
        margin-left: 0 !important;
    }

    & > *:last-child {
        flex: 0 0 93% !important;

        border: none;
        box-sizing: border-box;
        border-radius: 0;
    }
}

.manyBubbles {

}

.flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.flexColumn {
    display: flex;
    flex-direction: column;

    & > * {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
}

.bubble {
    color: var(--main-font-color);
    padding: 24px;

    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;
}

.fixedBubble {
    width: 650px;
    margin-right: 25px;
    margin-bottom: 25px;
    //@media (max-width: 992px) {
    //  width: 300px;
    //}
}


