.mainPart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 40px;

  & > *:first-child {
    flex: 0 0 calc(20.127% - 24px);
    margin-top: -24px;
  }

  & > *:last-child {
    flex: 0 0 79.5%;
    padding-left: 20px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;

    @media (max-width: 992px) {
      flex: 0 0 100%;
    }
  }
}

.minimalDesign {
  & > *:first-child {
    flex: 0 0 24px !important;
  }

  & > *:last-child {
    flex: 0 0 93% !important;
  }
}

.flex {
  color: var(--main-font-color);
  padding: 24px;
}

.textareaWhite {
  height: 250px;
  resize: none;
  width: 98%;
  margin-right: 20px;

  border-radius: 13px;
  color: black;

  padding-left: 10px;

}

.textareaWhite:hover {
  background-color: white;
  color: black;
}

.timer {
  color: white;

  .Support {

  }
}

.invisible {
  display: none;
}

.errorMsg {
  font-size: 14px;
  padding-right: 100px;
}

.errorMsgOuter {
  font-size: 14px;
}

.managersMail {
  text-align: left;

  .managerLabel {
    display: inline-block;
    min-width: 300px;
  }

  .managerName {
    display: inline-block;
  }

  .checkBox {
    display: inline-block;
    margin-left: 0;
  }
}

.mainPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #555;
  background-color: white;
  min-width: 550px;
  min-height: 50px;
  max-width: 700px;
  padding: 25px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  //box-shadow: unset 0 4px rgba(0 0 0 / 8%);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: column;

  & > :nth-last-child(even) {
    margin: 10px 0;
  }

  & > :first-child {
    align-self: center;
  }

  .messInfo {
    color: red;
    opacity: 0.6;

    text-align: right;
    padding-right: 10px;
  }
}

.messFormContacts {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > :first-child {
    margin-right: 10px;
  }
}
