.charts {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-top: 15px;
}

.chartsSize {
  height: 300px;
}

.selector {
  display: flex;
  font-size: 12px;

  & > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 2px 5px;
    border-bottom: 1px dotted black;
    cursor: pointer;
    color: var(--orange-color)
  }

  & > div:hover {
    background-color: var(--orange-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }

  .selected {
    background-color: var(--green-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);

  }

  .noSelected {
    word-break: break-word;
  }
}

.selectorDarkTheme {
  display: flex;
  font-size: 12px;

  & > div {
    border-bottom: white;
    color: red;
  }
}

.tooltipElem {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 5px 5px 5px;
  color:white;

  border-radius: 5px;

  & > p {
    padding: 0;
    font-weight: bold;
  }
}

.pad {
  padding: 5px 5px 5px 5px;
}

.spinner {
  width: 100%;
  background-color: white;

  display: flex;
  justify-content: center;
  align-content: space-between;

  & div {
    margin-bottom: 0;
  }


}