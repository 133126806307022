.tableRow {
  height: 50px;
  padding: 5px 0;

  & td {
    padding: 0 10px;
    max-width: 150px;
  }

  .reCallButton {
    display: flex;
    align-items: center;
    height: 60px;

    & > * {
      margin-left: 5px;
    }
  }

  .reCallButton svg:hover  {
    fill: green;
  }

  .hoverButton {
    cursor: pointer;
  }

  .hoverButton:hover {
    color: green;
  }

  .bWithTt:hover > .toolTip {
    display: block !important;
  }

  .tippy {
    cursor: default;
    position: absolute;
    margin: 25px;

    display: none;
    width: 150px;

    background-color: var(--background-gradient-light-end);
    border-radius: 10px;
    padding: 5px 0;

  }

  .callPath {
    cursor: default;
    position: absolute;
    margin-left: 60px;
    max-width: 250px;

    display: none;

    background-color: var(--background-gradient-light-end);
    border-radius: 10px;
    border: 1px solid black;
    padding: 5px 10px;
  }

  .commentButton {
    cursor: pointer;
  }

  .commentButton:hover .tippy {
    display: block;
  }

  .ivrTheme {
    cursor: pointer;
  }

  .ivrTheme:hover .callPath {
    display: block;
  }

  a:hover {
    color: #17a439;
  }

}

.tableFilter > * {
  background-color: var(--diagramma-background-light);

  input {
    background-color: inherit !important;
  }

  @media (max-width: 1400px) {
    //width: 94px;
  }
}

.tableFilter {
  //display: block;
  height: 25px;
  //padding: 5px 0;

  td {
    height: 15px;
    max-width: 70px;
  }

}

.svg {
  fill: red;
}