.flex {
  display: flex;
  align-items: center;
  //margin-top: 24px;
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}

.paragraph {
  margin: 0 24px;
}
