.MenuTooltip {
  display: flex;
  align-items: center;

  min-height: 15px;
  padding: 10px;

  background-color: #00a1e3;
  z-index: 9999;

  border-radius: 10px;
  transition: all 0.3s ease;

  color: var(--light-color);

  & > .MenuTitleGif {
    margin: 0 10px;
    min-width: 220px;
    width: 100%;
  }
}