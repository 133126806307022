.buttonsTop {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 24px;
    & > * {
        margin: 0 5px;
    }
}

.formTableM {
    td, th {
        border: 1px solid var(--border-default);
    }

    td {
        height: 15px;
    }

    th {
        text-align: center;
    }

    .actionB {
        cursor: pointer;
        &:hover {
            color: var(--blue-color);
        }

        padding: 0 5px;
    }
}

.td_padding_10 {
    td {
        padding: 2px 10px;
    }

}

.flexBlock {
    display: flex;
    justify-content: center;
}

.margin {
    & > * {
        margin: 0 10px;
    }
}

.tbody {
    input {
        border: none !important;
    }

    input:not(read-only):hover {
        border-radius: 0;
    }
    input:read-only {
        border: none !important;
        cursor: default;
    }
}

.errorElem {
    background-color: rgba(255, 0, 0, 0.28) !important;
}

.shake {
    animation: 1.2s ease-in-out 0s normal none trambling-animation;
}

.pagination {
    & > :first-child {
        padding: 0 0 10px 0 !important;
    }
}