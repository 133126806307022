.feature {
  color: var(--main-font-color);
}

.flex {
  display: flex;
  align-items: flex-start;
}

.eventTableBlock {
  margin-top: 25px;
}

.mainFrame {
  margin-left: 20px;
  width: 100%;
  min-height: 500px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid var(--light-border);

  text-align: center;
}

.list {
  width: 200px;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid var(--light-border);

  text-align: center;

  .elem {
    padding: 5px 0;
    cursor: pointer;

  }

  .elem:hover {
    color: var(--blue-color);
    text-decoration-style: dashed;
  }

}

.checkboxWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  z-index: 10;
  width: 50px;
  height: 20px;
  margin: 2px 0 0 0;
  cursor: pointer;
}

.newCheckbox {
  width: 40px;
  height: 20px;
  margin: 2px 0 0 0;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease;

  &::before {
    content: '';
    background: var(--blue-color);
    position: absolute;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    top: 0;
    left: 0;
    opacity: 0.1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--blue-color);
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &.active {
    &::before {
      background: var(--blue-color);
    }

    &::after {
      transform: translateX(21px);
    }
  }

  &.light.disabled {
    &::before {
      background: #e5e5e5;
      opacity: 0.8;
    }

    &::after {
      background: #e5e5e5;
      filter: contrast(0.5);
    }
  }

  &.dark.disabled {
    &::before {
      background: #e5e5e5;
      opacity: 0.05;
    }

    &::after {
      background: #e5e5e5;
      filter: contrast(0.5);
    }
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.menuBurger {
  margin-top: 5px;
  img {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 961px) {
    display: none;
  }

  @media (max-width: 470px) {
    margin-right: 24px;
  }
}

.title {
  font-size: 26px;
  padding-bottom: 5px;
  margin-bottom: 24px;
  color: var(--green-color);
}

.name {
  font-size: 20px;
  margin: 5px 0;
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 25.89px;
  line-height: 31px;
  color: var(--green-color);

  & > span {
    font-weight: bold;
  }
}

.device {
  font-size: 20px;
  margin: 5px 0 10px;

  & > span {
    font-weight: bold;
  }
}

.infoBlock {
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  & > * {
    @media (max-width: 450px) {
      flex: 0 0 100%;
    }
  }

  & > *:first-child {
    padding-right: 20px;
    margin-bottom: 12px;

    @media (max-width: 450px) {
      padding: 0;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.loading {
  display: flex;
  justify-content: center;
}

.diagramWrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.serviceBlock {
  margin: 25px 0;
}

.paramData {
  margin: 10px;
}

.paramName, .paramValue {
  display: inline-block;

  height: 25px;
  min-width: 270px;
  vertical-align: middle;
}



.paramInput {
  width: 25px;
  padding: 0 10px;
  border-radius: 5px;

  text-align: center;
}

.paramSubmit {
  border-radius: 5px;
  width: 95px;

  margin-left: 10px;

  background-color: var(--orange-color) !important;
  color: white  !important;
  cursor: pointer;

}

.paramSubmit[type=submit][disabled] {
  background-color: var(--light-color) !important;
}

.paramSubmit:hover {
  background-color: var(--night-color) !important;
  color: white  !important;
}

.table {
  color: var(--main-font-color);
  margin-left: 25px;
  text-align: center;
  font-size:0.8rem;
  display: block;
  border-collapse: collapse;
  overflow-x: auto;
  scrollbar-color: var(--main-font-color) var(--balance-card-background-st); /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: 12px;

  & tr:nth-child(even) {
    background-color: var(--light-color);
    color: var(--night-color);
  }

  & th {
    min-width: 100px;
    max-width: 100px;
    text-align: center;

    @media (max-width: 1400px) {
      min-width: 90px;
    }
  }
  /* полоса прокрутки (скроллбар) */
  &::-webkit-scrollbar {
    height: 12px; /* высота для горизонтального скролла */
    background-color: var(--balance-card-background-st);
    border-radius: 9em;
    margin-top: 5px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-font-color);
    border-radius: 9em;
    margin-top: 5px;
  }
}

.tableHead {
  background: var(--balance-card-background-st);
  font-family: 'PF BeauSans Pro';
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 50px;

  & > th {
    padding: 10px 10px;
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;

    &:not(:last-child) {
      border-right: 1px solid #e5e5e5;
    }

    span:not(.rotate) {
      margin-top: 5px;
    }
  }
}

.rotate {
  transform: rotate(-180deg);
}

.none {
  display: none;
}


