.mainBlock {
    margin: 24px 0;
}

.flexBlocks {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    & > * {
        margin-left: 24px;
    }
}

.noActiveService {
    background-color: lightcoral !important;
}

.activeService {
    background-color: lightgreen;
}

.serviceStatus {
    margin: 14px 0;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        padding-left: 24px;
        font-weight: bold;
    }

    & > :last-child {
        margin-right: 24px;
    }
}

.formBlock {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    margin: 24px 0;

    @media (max-width: 960px) {
        padding-left: 0;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.blockTitle {
    flex: 1 1 60%;
    padding-right: 24px;
}

.formSmall {
    width: 500px;
    padding: 24px;
    margin: 24px 24px 0;

    border: 1px solid var(--border-default);
    border-radius: 10px;
}

.ivrConfig {
    display: flex;
    flex-direction: column;
    background-color: #3e98c7;
    border-radius: 10px;
    color: white;
    padding: 10px;
}

.labelWButton {
    & > :last-child {
        float: right;
    }
}

.textArea {
    padding: 5px;
    width: 100%;
    &:hover {
        color: var(--blue-color);
    }


    & > textarea {
        width: inherit;
        min-height: 100px !important;
        height: 100px !important;
        border: 1px solid var(--border-default);
        border-radius: 10px;
        overflow-y: auto;
        &:focus {
            outline: none !important;
            border: 1px solid var(--blue-color);
        }
    }

    &:focus {
        outline: none !important;
        border: 1px solid var(--blue) !important;
    }
}

.toBottom {
    margin-top: auto;
}

.editArrayDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 700px;

    & > input {
        margin: 0;
        padding: 0 5px;
        width: 75px;
        height: 20px;

        border-radius: 0;
        border: 1px solid var(--border-default);
    }

    & > .addInput {
        font-size: 24px;
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid var(--border-default);
        line-height: 24px;
        height: 24px;
        padding: 0 5px;

        margin: 0 30px;

        &:hover {
            border-radius: 50%;
            border: 1px solid var(--border-default);
            color: white;
            background-color: var(--blue);
        }
    }
}

.editArrayButtons {
    display: flex;
    justify-content: center;

    & > * {
        margin-right: 24px;
    }

    & > :last-child {
        margin-right: 0;
    }
}

.diap {
    input {
        width: 25px;
    }

    label, span {
        margin-right: 10px;
    }
}

.shake {
    animation: 1.2s ease-in-out 0s normal none trambling-animation;
}

@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-10deg);
    }
    20%, 40% {
        transform: rotate(10deg);
    }
}

.helpText {
    color: var(--orange-text-dark)
}

