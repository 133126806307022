.flex {
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: start;
  }
}

.title {
  color: var(--main-font-color);
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 25.89px;
  line-height: 31px;
  margin-right: 10px;

  @media (max-width: 450px) {
    margin-bottom: 24px;
  }
}
