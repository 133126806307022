@import '../../variables.scss';



.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.svg {
  width: 210px;
}

.menu {
  display: flex;
  justify-content: center;
  padding: 0;

  @media (max-width: 992px) {
    display: none;
  }
}

.rightSide {
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    display: none;
  }
}


.features {
  display: flex;
  align-items: center;
  border-right: 1px solid $line-light;
  cursor: pointer;

  p,
  a {
    margin-right: 20px;
    width: 20px;
    height: 20px;
  }
}

.help {
  margin-left: 24px;
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 150%;
  color: var(--header-font-color);

  &:hover {
    text-decoration: underline;
  }
}

.menuBurger {
  z-index: 50;
  img {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 993px) {
    display: none;
  }
}

.titleBar {
  color: var(--main-font-color);
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;

  h1 {
    font-family: 'PF BeauSans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 150%;
    margin: 0 37px 0 0;
    max-width: 700px;
  }

  p {
    font-family: 'PF BeauSans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: var(--green-color);
    margin: 10px 37px 0 0;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: start;

    h1 {
      font-size: 30px;
      line-height: 1.25;
      margin-bottom: 12px;
    }
  }
}
