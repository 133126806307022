.chooseDevice {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-radius: 20px;
  padding: 12px 24px;
  box-sizing: border-box;
  background: var(--balance-card-background-st);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;


  & > *:first-child {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 1200px) {
    width: 90%;
    justify-content: space-around;
  }

  @media (max-width: 960px) {
    display: flex;
    justify-content: center;
    width: auto;
  }

  @media (max-width: 740px) {
    width: 90%;
    justify-content: space-around;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > *:first-child {
      margin-bottom: 10px;
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: start;
  }

  @media (max-width: 960px) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 740px) {
    flex-direction: column;
    align-items: start;
  }

  & > *:first-child {
    margin-right: 24px;
  }

  & > * {
    min-height: 24px;
  }

}

.AllAtsChbLabel {
  min-height: 5px !important;
  vertical-align: top;
}

.cursor {
  cursor: pointer;
}

.bubble {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-radius: 20px;
  padding: 12px 24px;
  box-sizing: border-box;
  background: var(--balance-card-background-st);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}