@import '../../../variables.scss';

.link {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 25.89px;
  line-height: 150%;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--main-font-color);
  }
}

.color {
  color: var(--header-font-color);
}
