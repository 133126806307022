.mainBlock {
    margin: 24px 0;

    .title > * {
        margin-right: 10px;
    }

    max-width: 100%;
    width: 100%;
}

.tableBlock {
    max-width: 100%;
    width: 100%;
    overflow-x: auto;

}

.formTableM {
    th {
        cursor: pointer;
    }

    th:hover {
        color: var(--orange-color);
    }

    .sortedUp {
        border-bottom: 1px solid var(--blue-color);
    }

    .sortedDown {
        border-top: 1px solid var(--blue-color);
    }

    .noHover, .noHover:hover {
        color: inherit !important;
        cursor: default;
    }
}

.noCr {
    display: inline-block;
    width: 29px;
    margin: 0 5px;

    &:first-child {
        cursor: pointer;
    }

    &:hover {
        color: var(--green-color);
        //border: 1px solid var(--orange-color);
    }
}

.audioButton {
    display: inline-block;
    width: 29px;
    margin: 0 5px;

    &:first-child {
        cursor: pointer;

        color: white;
    }

    &:hover {
        color: var(--orange-color)
    }
}

.bPlayer {
    display: flex;
}

.audioDownload {
    display: inline;
    margin: 0 5px;
    line-height: 29px;
    svg {
        width: 25px;
        height: 25px;
        vertical-align: center;
        font-size: 1.5em;
        border-radius: 50%;
        color: black;

        &:hover {
            color: var(--green-color)
        }
    }
}

.flexedForm {
    display: flex;
    flex-direction: column;
    flex:1;

    & > :last-child {
        width: 100%;
        margin-top: auto;
    }
}

.buttonsTop {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
        margin: 0 5px;
    }
}

.action {
    cursor: pointer;
    &:hover {
        color: var(--blue-color);
    }

    padding: 0 5px;
}

.formTable {
    th {

        padding: 5px 10px;
        text-align: center;

        cursor: pointer;

        &:not(:last-child) {
            border-right: 1px solid #e5e5e5;
        }

        &:first-child {
            width: 80px
        }

        span {
            display: inline-block;
        }
    }

    .metersHeader {
        width: 70px;
        writing-mode: vertical-lr;
    }

    tr:nth-child(even) {
        background-color: var(--light-color);
        color: var(--night-color);
    }

    td {
        text-align: center;
    }

    td {

    }

    tbody {
        tr {
            font-size: 14px;
        }

        td {
            padding: 0 5px;
        }
    }
}

.rotate {
    transform: rotate(-180deg) !important;
}

.tableFilter > * {
    background-color: var(--diagramma-background-light);
}

.tableFilter {
    input {
        background-color: inherit !important;
    }
}

.comment {
    width: 100%;
    text-align: left;

    font-size: 14px;
}

.realCheckBox {
    display: none;
}

.flexedCb {
    display: flex;
    justify-content: center;
}

.fakeCb {
    position: relative;
    width: 46px;
    height: 24px !important;

    background-color: #ebebeb;
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid #dbdbdb;
    border-radius: 18px;

}

.fakeCb::before {
    content: "";

    width: 24px !important;
    height: 24px !important;
    margin: -1px 0 0 -1px;

    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 18px;

    transition: 0.2s ease-in;
    float: left;
}

.realCheckBox:checked + .fakeCb::before {
    float: right;
    background-color: var(--blue-color);
    opacity: 0.5;
}

.shake {
    animation: 1.2s ease-in-out 0s normal none trambling-animation;
}

@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-10deg);
    }
    20%, 40% {
        transform: rotate(10deg);
    }
}
