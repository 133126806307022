.width {
  width: calc(98vw);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  &.active {
    z-index: 15;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.header > p > a {
  color: var(--main-font-color);

  &:hover {
    text-decoration: underline;
  }
}

.virtual {
  border-top: 1px solid var(--main-font-color);
  border-bottom: 1px solid var(--main-font-color);
  margin-top: 24px;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.footer {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  align-items: center;
}

.result {
  margin-right: 24px;
  font-weight: 700;
}

.button {
  background: var(--orange-color);
  color: #fff;
  display: inline-block;
  padding: 4px 6px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: var(--night-color);
    color: #fff;
  }
}
