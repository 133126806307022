.wrapper {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.settings {
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }
}

.paragraph {
  margin: 0 24px;

  @media (max-width: 340px) {
    word-break: break-all;
  }
}

.flex {
  display: flex;
  align-items: center;

  & > p:first-child {
    margin-right: 24px;
  }
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}
