.tabs {
  margin: 25px 0;

  display: flex;

  & .tab {
    background-color: var(--green-color);
    color: white;
    border-radius: 5px;
    cursor: pointer;

    padding: 5px 10px;
    margin-right: 5px;
  }

  & .activeTab {
    background-color: var(--orange-color);
  }

  & .tabNc {
    cursor: pointer;

    padding: 5px 10px;
    margin-right: 5px;
    border-right: 1px solid var(--border-default);

  }

  & .activeTabNc {
    border-bottom: 2px solid var(--orange-color);
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }
}

.selector {
  display: flex;

  font-size: 12px;

  & > div {
    margin: 0 5px;
    padding: 2px 5px;
    border-bottom: 1px dotted black;
    cursor: pointer;
  }

  & > div:hover {
    background-color: var(--orange-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }

  .selected {
    background-color: var(--orange-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);

  }

  .noSelected {
    word-break: break-word;
  }
}

.noMargin {
  margin: 0;
}