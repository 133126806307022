.mainBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > *:first-child {
    flex: 0 0 65%;
  }

  & > *:last-child {
    flex: 0 0 calc(35% - 24px);
    width: calc(35% - 24px);
    margin-left: 24px;
    min-height: 100%;
  }

  @media (max-width: 960px) {
    flex-direction: column;

    & > * {
      flex: 0 0 100%;
      margin: 0;
    }

    & > *:last-child {
      margin-top: 24px;
      margin-left: 0;
      width: 576px;
    }
  }

  @media (max-width: 768px) {
    & > *:last-child {
      width: 75vw;
    }
  }

  @media (max-width: 450px) {
    & > *:last-child {
      width: 90vw;
    }
  }
}



.slider {
  max-width: 65%;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid var(--border-default);
  background: linear-gradient(
    61deg,
    var(--balance-background-st),
    var(--balance-background-end)
  );

  @media (max-width: 960px) {
    max-width: 100%;
  }
}

.sliderMain {
  display: flex;
  height: 90px;
}

.sliderFullW {
  display: flex;
  width: 96%;
}

.balanceSmall {

}

.minimalDesign {

}

.sliderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 25px;

  @media (max-width: 430px) {
    flex-direction: column;
    align-items: start;
  }
}

.sliderParagraph {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 25.89px;
  color: var(--main-font-color);
  margin: 0;

  @media (max-width: 430px) {
    margin-bottom: 10px;
  }
}

.btn {
  padding: 6px 10px 7px;
  background: var(--orange-color);
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'PF BeauSans Pro';
  cursor: pointer;
  border: none;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    background: var(--night-color);
    color: #fff;
  }
}

.btnH {
  height: 100%;
  margin-left: 15px;
}
