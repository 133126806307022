
.flex {
  color: var(--main-font-color);
  padding: 24px;
}

.table {
  color: var(--main-font-color);
  text-align: center;
  font-size:0.8rem;
  border-collapse: collapse;

  overflow-x: auto;

  & tr:nth-child(even) {
    background-color: var(--light-color);
    color: var(--night-color);
  }

  .tableHead {
    height: 80px;

    & > th {
      cursor: pointer;
      width: 150px;

      &:not(:last-child) {
        border-right: 1px solid #e5e5e5;
      }
    }

    & > th > span {
      display: inline-block;
    }


  }

}

.rotate {
  transform: rotate(-180deg) !important;
}

.tableInactive {
  color: var(--main-font-color);
  text-align: center;
  font-size:0.8rem;
  border-collapse: collapse;

  overflow-x: auto;

  & tr:nth-child(even) {
    background-color: var(--light-color);
    color: var(--night-color);
  }

  & tr {
    opacity: 0.5;
    cursor: none;
  }

  .tableHead {
    height: 80px;

    & > th {
      cursor: none;
      width: 150px;

      &:not(:last-child) {
        border-right: 1px solid #e5e5e5;
      }
    }


  }

}

.commentBlock {
  border-radius: 5px;
  width: 100%;
  height: 150px;

  resize: none;
}

.callTable {
  font-size: 18px;
  text-align: left;
  margin-right: 10px;

  & tr {
    height: 50px;
  }
}
.numberInput {
  border-radius: 5px;
  width: 150px;

}

.bigButton {
  width: 100%;
  height: 100px;

  border: 1px solid var(--border-default);
  border-radius: 30px;
  line-height: 100px;

  background-color: white;
  color: var(--buttons-text-color-dark);

  cursor: pointer;
}

.bigButton:hover {
  background-color: var(--green-color);
  color: white;
  transition: all 0.2s linear;
}

.featuresMain {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
}

.featureBlock {
  font-size: 18px;

  & .featureLabel {
    font-weight: bold;
    text-align: left;
  }

  & .featureText {
    text-align: left;
    font-style: italic;
  }
}

.checkBox {
  font-style: italic;
  font-size: 16px;
  text-align: right;
  color: #a3a3a3;

  & label {
    margin: 0 10px ;
  }
}

.featureButton {
  line-height: 55px;
  cursor: pointer;
  width: 250px;
  transform: rotate(-90deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.rotateButton {
  color: #a3a3a3;

  line-height: 50px;
  cursor: default;
  width: 250px;
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.featureButton:hover {
  color: #17a439;
}

.bigStatusGreen {
  width: 100%;
  height: 100px;

  border: 1px solid var(--border-default);
  border-radius: 30px;
  line-height: 100px;
  background-color: var(--green-color);

}

.bigStatusRed {
  width: 100%;
  height: 100px;

  border: 1px solid var(--border-default);
  border-radius: 30px;
  line-height: 100px;
  background-color: var(--orange-color);

}

.newFeatureForm {
  & > input {
    border-radius: 5px;
  }
}

.formBlock {
  display: block;
  font-size: 20px;
  width: 400px;


  & input {
    border-radius: 5px;
  }

  & textarea {
    border-radius: 5px;
    width: 370px;
    min-height: 100px;
    resize: none;
  }

  & .formRow {
    display: flex;
    justify-content: left;
    margin: 10px 0;

    & div {
      text-align: left;
      max-width: 140px;
    }

    & :nth-child(1) {
      min-width: 200px;
    }

    .errorButton {
      background-color: rgba(255, 0, 0, 0.2);
      color: red;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .bigButton {
    width: 200px;
    height: 45px;
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: bold;

    border: 1px solid var(--border-default);
    border-radius: 5px;
    line-height: 45px;

    cursor: pointer;
  }

  & .noActiveButton {
    width: 200px;
    height: 45px;
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: bold;
    color: rgba(163, 163, 163, 0.50);

    border: 1px solid var(--border-default);
    border-radius: 5px;
    line-height: 45px;

    cursor: no-drop;
  }

  & .bigButton:hover {
    background-color: var(--green-color);
    color: white;
    transition: all 0.2s linear;
  }

  .processed {
    cursor: wait;
  }

  .deleteImageB {
    font-size: 16px;
    cursor: pointer;
  }

  & .deleteButton {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;

    font-size: 16px;
    cursor: pointer;
  }

  & .deleteButton:hover {
    background-color: var(--orange-color);
    color: white;
  }
}

.imageUploader {

}

.dropLabel {
  font-size: 18px;
}

.errorMessage {
  color: red;
  font-size: 18px;
  max-width: 200px;
}

.greenBG {
  background-color: var(--green-color);
}

.yesNoStyle {
  font-size: 18px;

  & > * {
    margin: 0 10px;
  }
}