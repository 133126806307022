.fullSizedWrap {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(99, 99, 100, 0.5);

  & .modalWindow {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #555;
    background-color: white;
    min-width: 550px;
    min-height: 50px;
    max-width: 700px;
    padding: 25px;
    text-align: center;
    border-bottom: 1px solid #dbdbdb;
    border-radius: 10px;
    box-shadow: unset 0 4px 0 rgb(0, 0, 0, 0.8);

    & .messageBlock {
      font-size: 18px;

      & > p > a {
        color: var(--blue-color)
      }
    }

    & .closeButton {
      margin-top: 24px;

    }

    & .basketBlock {
      & .basketElem {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 10px 0;

        & > div {
          text-align: left;
        }


        & .title {
          min-width: 350px;
          display: flex;
          flex-direction: column;

          & > span {
            font-size: 14px;
            display: flex;
            justify-content: flex-end;

            border-top: 1px dashed var(--border-default);
            border-bottom: 1px dashed var(--border-default);
            padding: 2px 0;
          }
        }

        & .price {
          display: flex;
          align-items: center;

          min-width: 100px;
          color: var(--green-color);
        }

        .priceIndivid {
          font-size: 14px;
          color: var(--blue-color);
        }

        & .deleteB {
          cursor: pointer;

          &:hover {
            color: var(--orange-color)
          }
        }


      }

      & > :last-child {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        margin-top: 24px;

        & > * {
          margin: 0 24px;
          vertical-align: center;


        }

        & > :first-child {
          font-weight: bold;
        }
      }
    }
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

.grey {
  background-color: #6c6c6c;
}

.noNumbers {
  font-size: 12px;
  font-weight: bold;
}
