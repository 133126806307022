.image {
  border-radius: 25px;
  width: 100%;
  height: 202px;

  @media (max-width: 1230px) {
    height: auto;
  }
}

.swiperSlide {
  box-sizing: border-box;
}

.reclam {
  background: url(../../../../img/marketing/ops.jpg) no-repeat;
  width: 438px;
  height: 202px;
}
