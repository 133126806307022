.mainBlock {
    margin: 24px 0;
    max-width: 100%;
    width: 100%;
}

.action {
    cursor: pointer;
    &:hover {
        color: var(--blue-color);
    }
}

.searchField {
    display: flex;
    align-items: center;
    span {
        padding: 0 5px;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 5px;
        margin: 0 10px;

        border: 1px solid var(--border-default);
        border-radius: 5px;
        background-color: white !important;
        &:focus {
            outline: none !important;
            border: 1px solid var(--blue-color);
        }

        option {
            padding: 5px;
        }
    }
}

.searchB {
    margin-left: 10px;
    display: flex;
    align-items: center;
    & > :first-child {
        height: 100% !important;
    }
}

.flexedForm {
    display: flex;
    flex-direction: column;
    flex:1;

    & > :last-child {
        width: 100%;
        margin-top: auto;
    }
}

.buttonsTop {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 24px;

    & > * {
        margin: 0 5px;
    }
}

.comment {
    width: 100%;
    text-align: left;

    font-size: 14px;
}

.shake {
    animation: 1.2s ease-in-out 0s normal none trambling-animation;
}

@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-10deg);
    }
    20%, 40% {
        transform: rotate(10deg);
    }
}

.flexBlock {
    display: flex;
}

.spaceBetween {
    justify-content: space-between;
}

.margin {
    & > * {
        margin: 0 10px;
    }
}

.orangeChild {
    & > div {
        background-color: var(--orange-color) !important;
    }
}

.blueChild {
    & > div {
        background-color: var(--blue-color) !important;
    }
}

.childPadding {
    & > div {
        padding: 0 20px !important;
    }
}

.formTable {
    display: flex;
    flex-direction: column;

    max-width: 100%;
    width: 100%;
    overflow-x: auto;
}