.flex {
  display: flex;
  margin-top: 24px;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  @media (max-width: 850px) and (min-width: 700px) {
    margin-top: 0;
  }
}
