.animation {
    animation-duration: 1s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0;
        width: 100%;
    }
}

.statistics {
    font-size: 12px;
    & > span {
        padding: 5px 12px;
        cursor: pointer;

        &:hover {
            background-color: var(--blue-color);
            color: white;
        }

        &:hover::after {
            content: "Нажмите для фильтрации";

            position: absolute; /* Абсолютное позиционирование */
            //left: 20%; top: 30%; /* Положение подсказки */
            margin-left: 10px;
            z-index:999; /* Отображаем подсказку поверх других элементов */
            background: var(--background-gradient-st); /* Полупрозрачный цвет фона */
            color: var(--main-font-color);

            border: 1px solid var(--border-default);
            border-radius: 10px;
            padding: 5px 5px;
            width: 100px;
        }
    }

    .active {
        background-color: var(--green-color);
        color: white;
    }
}

.smsList {
    display: flex;
    flex-direction: column;

    table {
        margin: 10px 0;
    }

    .aMore {
        color: var(--green-color);
        cursor: pointer;
    }

    table > thead {
        background-color: var(--blue-color);
        color: white;

        th {
            padding: 0 5px;
        }

    }

    table > tbody > tr > td {
        font-size: 12px;
        padding: 0 5px;
    }

    table > tbody > :nth-child(even) {
        background-color: var(--border-default);
    }

    table > tbody > tr {
        height: 20px;
        & .datetime {
            min-width: 120px;
            text-align: center;
        }

        & .text {
            text-align: left;
        }
    }


    .buttons {
        display: flex;
        justify-content: flex-end;

        & > * {
            margin: 0 10px;
        }
    }
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.h250 {
    height: 250px;
}

.buttonsTop {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
        margin: 0 5px;
    }
}

.btn {
    padding: 4px 10px 6px;
    background: var(--orange-color);
    color: #fff;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    min-width: auto;
    cursor: pointer;
    font-family: 'PF BeauSans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    transition: all 0.3s ease;

    &:hover {
        background: #323232;
        color: #fff;
    }

    @media (max-width: 450px) {
        margin-bottom: 24px;
    }
}

.btnDownload {
    float: right;
    background-color: var(--green-color);
}

.SvgElem {
    font-size: 1em;
    vertical-align: middle;
}

.frame {
    width: 100%;
    position: relative;
    height: 500px;

    iframe {
        width: 100%;
        height: 480px;
        border: 0;
    }
}