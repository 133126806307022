.title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 24px;
    width: 100%;

}

.noActiveService {
    background-color: lightcoral !important;
}

.activeService {
    background-color: lightgreen;
}

.serviceStatus {
    margin: 14px 0;
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        padding-left: 24px;
        font-weight: bold;
    }

    & > :last-child {
        margin-right: 24px;
    }
}

.formBlock {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    margin: 24px 0;

    @media (max-width: 960px) {
        padding-left: 0;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.blockTitle {
    flex: 1 1 60%;
    padding-right: 24px;
}

.subTitle {
    padding-left: 20px;
    font-size: 24px;
    min-width: 280px;

    @media (max-width: 960px) {
        padding-left: 0;
    }
}

.defaultInputReadOnly {
    flex: 0 0 40%;
    font-family: 'PF BeauSans Pro';
    max-width: 200px;
    font-size: 16px;
    background: transparent;
    border: 1px solid var(--border-default);
    padding: 9px 24px;
    border-radius: 5px;
    color: var(--light-color);
    outline: none;

    &.error {
        color: red;
    }

    @media (max-width: 600px) {
        margin-top: 24px;
    }

    &.noPadding {
        padding: 0 24px !important;
        cursor: pointer;
    }
}

.defaultInput {
    flex: 0 0 40%;
    font-family: 'PF BeauSans Pro';
    max-width: 350px;
    font-size: 16px;
    background: transparent;
    border: 1px dotted var(--main-font-color);
    padding: 9px 24px;
    border-radius: 5px;
    color: var(--main-font-color);

    &.error {
        color: red;
        //padding-left: 35px;
        //box-sizing: border-box;
    }

    @media (max-width: 600px) {
        margin-top: 24px;
    }

    &.noPadding {
        padding: 0 24px !important;
        cursor: pointer;
    }
}

.errorMes {
    position: absolute;
    color: red;
    font-size: 12px;

    line-height: 35px;
    padding-top: 12px;
    z-index: -1;
}

.menuBurger {
    margin-top: 5px;
    img {
        width: 25px;
        height: 25px;
    }

    @media (min-width: 961px) {
        display: none;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.floatR {
    float: right;
}

.button {
    padding: 4px 10px 6px;
    border-radius: 10px;
    background: var(--orange-color);
    font-family: 'PF BeauSans Pro';
    color: #fff;
    border: unset;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;

    @media (min-width: 960px) {
        margin-left: 20px;
        font-size: 16px;
    }

    &:hover {
        background: #323232;
        color: #fff;
        border: unset;
    }
}

.historyButton {
    cursor: pointer;
    max-width: 300px;
    text-align: center;

    color: var(--blue-color);
    text-decoration: underline;
}

.shake {
    animation: 1.2s ease-in-out 0s normal none trambling-animation;
}

@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-10deg);
    }
    20%, 40% {
        transform: rotate(10deg);
    }
}