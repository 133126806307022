.greetingsModule {
    padding: 0 24px;

    & > * {
        margin-bottom: 24px;
    }
}

.greetingsMain {

}


.bordered {
    border: 1px solid var(--border-default);
    border-radius: 10px;
}

.audioTextArea {
    //display: flex;
    //flex-direction: column;
    .textArea {
        &:focus {
            outline: none !important;
            border: 1px solid var(--blue) !important;
        }

        & > textarea {
            width: inherit;
            min-height: 180px !important;
            height: 180px !important;
            border: none;
            border-radius: 10px;
            overflow-y: auto;

            &:focus {
                outline: none !important;
                border: 1px solid var(--blue) !important;
            }
        }
    }


}

.buttonsBlock {
    position: inherit;

    width: inherit;
    @media (max-width: 1080px) {
        min-width: 0 !important;
    }

    @media (max-width: 815px) {
        min-width: 0 !important;
    }


    display: flex;
    flex-wrap: wrap;

    & > *:hover {
        border-top: 1px solid var(--blue);
        padding: 4px 0 0 5px;
    }
}

.voiceSelect {
    display: flex;
    flex-direction: column;

    width: 150px;
    height: 55px;
    padding: 5px 0 0 5px;
    & > label {
        font-size: 12px;
    }
}

.voiceRange {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 5px 0 0 5px;
    width: 350px;
    height: 50px;
    & > label {
        font-size: 12px;
    }

    .slider {
        width: 300px;
        font-size: 8px;
        margin-left: 20px;
        margin-top: 0;
    }
}

.voiceButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 50px;
    padding: 5px 10px;

    &:hover {
        padding: 5px 10px;
        border: 0;
    }
}

.borderedInput {
    border: 1px solid var(--blue);
    border-radius: 10px;
}

.wrapFlex {
    display: flex;
    flex-wrap: wrap;
}

.groupedButtons {
    & > * {
        width: 400px;
        margin: 12px 10px 12px 0;
        padding-right: 10px;

        display: flex;
        justify-content: space-between;

        border-right: 1px solid var(--border-default);
    }
}

.childrenWidth100 {
    & > * {
        width: 100% !important;
    }
}

.childrenMarg10 {
    & > * {
        margin: 10px 0 !important;
    }
}

.playerMain {

    display: flex;
    align-items: center;

    .player  {
        //position: relative;
        //margin-top: -60px;

        //width: 650px;
        width: calc(100% - 40px);
    }

    .buttonPlay {
        //position: relative;
        z-index: 1;
        //font-size: calc(4px + 2vmin);
        //margin-top: -45px;
        //margin-right: 20px;
        width: 30px;
        padding: 4px 0 0 0;
        float: right;
        background: var(--orange-color);
        font-family: 'PF BeauSans Pro';
        color: #fff;
        border: unset;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 5px;

        &:hover {
            background: #323232;
            color: #fff;
            border: unset;
        }
    }

    .buttonStop {
        color: white;
    }
}

.editArrayDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 700px;

    & > input {
        margin: 0;
        padding: 0 5px;
        width: 75px;
        height: 20px;

        border-radius: 0;
        border: 1px solid var(--border-default);
    }

    & > .addInput {
        font-size: 24px;
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid var(--border-default);
        line-height: 24px;
        height: 24px;
        padding: 0 5px;

        margin: 0 30px;

        &:hover {
            border-radius: 50%;
            border: 1px solid var(--border-default);
            color: white;
            background-color: var(--blue);
        }
    }
}

.editArrayButtons {
    display: flex;
    justify-content: center;

    & > * {
        margin-right: 24px;
    }

    & > :last-child {
        margin-right: 0;
    }
}

.filenameLabel {
    padding: 0 10px;

    input {
        margin: 0 10px;
    }
}

.shake {
    animation: 1.2s ease-in-out 0s normal none trambling-animation;
}

@keyframes trambling-animation {
    0%, 50%, 100% {
        transform: rotate(0deg);
    }
    10%, 30% {
        transform: rotate(-10deg);
    }
    20%, 40% {
        transform: rotate(10deg);
    }
}

.blockNames {
    input {
        font-size: 14px;
        line-height: 30px;
        border-radius: 10px;
        border-color: var(--blue-color);
        padding: 0 10px;
        font-family: "Open Sans";
    }

    & > * > * {
        margin-right: 24px;
    }
}

.buttonsColumn {
    display: flex;
    flex-direction: column;
}

.height250 {
    height: 250px;
}

.spaceBetw {
    justify-content: space-between
}

.labelInput {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
        max-width: 300px;
    }

    .borderedInput {
        width: 300px;
    }
}

.helpText {
    font-style: italic;
    font-size: 14px;
    color: darkred;
}