.slider {
  flex: 0 0 32.127%;
  margin-top: -24px;

  & > .sliderWrapper {
    height: 100%;
  }

  & > .sliderWrapper .swiper {
    height: 100% !important;
  }

  @media (max-width: 1230px) {
    & > .sliderWrapper {
      height: auto;
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
}
