//html {
//  background: url(../../../img/bg_modal_page_new.png) no-repeat center center fixed;
//  -webkit-background-size: cover;
//  -moz-background-size: cover;
//  -o-background-size: cover;
//  background-size: cover;
//}

.loginPage {
  font-family: 'PF BeauSans Pro' !important;
}

.loginBlock {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding-top: 15px;
  padding-bottom: 15px;

  border: 3px solid var(--border-default);
  border-radius: 25px;

  background-color: white;
}

.loginBlock .logo {
  width: 201px;
  height: 133px;
  background-image: url(../../../img/modal_logo.png);
  background-repeat: no-repeat;
  background-size: cover;

  margin: 15px auto 35px;
}

.loginBlock form {
  display: block;
  width: 100%;
  margin-top: 12px;
}

.loginBlock a {
  text-decoration: none;
}

.loginBlock form input {
  display: block;
  width: 70%;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

}

.loginBlock form input[type=text], .loginBlock form input[type=password] {
  padding: 17px 15px 12px 35px;
}

.loginBlock form .submit:hover {
  background-color: #00a1e3 ;
  color:white;

}

.bottom{
  color: #a3a3a3;
  font-size: 18px;
  text-align: center;
  width: 282px;
  margin: auto auto;
}

.loginBlock .bottom a {

}

.loginBlock .bottom .help {
  margin-bottom: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: #a3a3a3;
}



.timer {
  position: absolute;
  line-height: 48px;
  right: 50px;
  color: red;
}

.esia-button {

}

.esia-button:hover {

}

.sber-button {
  background: #fff !important;
  border: 1px solid #D0D7DD !important;
  border-radius: 25px;
  color: #606061;
  cursor: pointer;
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 1.5rem;
  list-style-type: none;

  transition: all 0.2s linear;
  padding: 0 24px;

  height: 45px;
  text-align: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.sber-button svg {
  position: relative;
  padding-right: 8px;
  fill: #107F8C;
}

.sber-button span {
  display: inline-block;
  position: relative;
  line-height: 45px;
  vertical-align: top;

}

.sber-button:hover {
  color: black;
  border: 1px solid #1F1F22 !important;
}

.sber-button:active {
  background-color: #D0D7DD !important;
}

.activate-button {
  display: inline-block;
}

.loginBlock .esiaIdIco {
  width: 110px;
  height: 24px;
  background-image: url(../../../img/icons/logo.d173a793363aa9157783.svg);
  background-repeat: no-repeat;
  background-size: contain;

  margin: 15px auto;
}

.companiesList {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  color: #555;
  background-color: white;
  padding: 25px;
  border-bottom: 1px solid #dbdbdb;
  border-radius: 25px;

}

.companiesList .user-data-block {
  vertical-align: bottom;
  min-height: 450px;
  max-width: 350px;

  padding: 0 10px 0 0;
  margin-right: 10px;

}

.companiesList .user-data-block .user-data {
  margin-bottom: 25px;
}

.user-data .fio {
  color: #0096d9;
  font-weight: bold;
}

.user-data-elem {
  line-height: 24px;
  height: 100%;
  border-bottom: 1px dashed #636364;
}

.user-data-elem div {
  min-width: 200px;
}

.user-data-elem span {
  display: inline-block;
}


.companiesList .companies-data-block {
  vertical-align: top;
  border-right: 1px solid #636364;
}

.companiesList .companies-data-block .company-data {
  width: 300px;
  min-height:90px;

  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;

  border: 3px solid #00a1e3;
}

.companiesList .companies-data-block .company-data .name {
  font-weight: bold;
  padding-bottom: 10px;
}

.companiesList .companies-data-block .company-data .ogrn {
  vertical-align: bottom;
  padding-bottom: 10px;
}

.companiesList .b {
  width: 100%;
  margin-bottom: 5px;
  line-height: 30px;
  background-color: #00a1e3;
  color: white;

  text-align: center;
  cursor: pointer;
}

.companiesList .b:hover {
  background-color: #17a439;
}

.companiesList .no-active {
  background-color: #b2b2b2 !important;
}

.companiesList .no-active:hover {
  cursor: help;
}

.button-text {
  display: inline;
  line-height: 32px;
}

@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-spinner {
  -webkit-animation-duration: 0.75s;
  -moz-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 16px;
  width: 16px;
  border: 3px solid #107F8C;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
}

.loading-spinner {
  margin: 10px 0;
}

.font-14 {
  font-size: 14px;
}

.margin-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.b {
  font-weight: bold !important;
}

.pad-top-25 {
  padding-top: 25px !important;
}

.pad-bot-25 {
  padding-bottom: 25px !important;
}

.pad-bot-10 {
  padding-bottom: 10px !important;
}

.pad-lr-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pad-tb-10 {
  padding: 10px 0;
}


.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-tb {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mar-top-25 {
  margin-top: 25px !important;
}

.mar-top-20 {
  margin-top: 20px !important;
}

.mar-top-10 {
  margin-top: 10px !important;
}

.mar-bot-25 {
  margin-bottom: 25px !important;;
}

.mar-bot-10 {
  margin-bottom: 10px !important;;
}

.mar-rt-15 {
  margin-right: 15px !important;;
}

.mar-lt-15 {
  margin-left: 15px !important;;
}

.mar-lr-10 {
  margin: 0 10px;
}

.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.invisible {
  display: none !important;
}

.loginBlock input {
  border-radius: 25px;
  border: 1px solid var(--border-default);
}
