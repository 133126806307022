.w250 {
  width: 250px;
}

.svgPicture {
  position: relative;

  & > div {
    height: 250px;
    margin-top: 24px;

    @media (max-width: 600px) {
      height: 190px;
    }
  }

  & > p {
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    color: var(--main-font-color);
  }
}

.info {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  font-size: 20px;

  @media (max-width: 450px) {
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 12px;
    }
  }
}

.criteria {
  min-width: 150px;
  text-align: center;
  font-weight: bold;

  & > p {
    font-size: 24px;

  }

  & > span {

  }
}
