.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  color: var(--night-color);

  visibility: hidden;
  transform: scale(0.8);
  transition: all 0.3s ease;

  &.active {
    opacity: 0.2;
    transform: scale(1);
    visibility: visible;
  }
}

.numberInput {
  border-radius: 5px;
  width: 150px;

}

.callTable {
  font-size: 18px;
  text-align: left;
  margin-right: 10px;

  & tr {
    height: 50px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bigButton {
  width: 200px;
  height: 45px;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: bold;

  border: 1px solid var(--border-default);
  border-radius: 5px;
  line-height: 45px;

  cursor: pointer;
}

.noActiveButton {
  width: 200px;
  height: 45px;
  margin-bottom: 10px;

  font-size: 16px;
  font-weight: bold;
  color: rgba(163, 163, 163, 0.50);

  border: 1px solid var(--border-default);
  border-radius: 5px;
  line-height: 45px;

  cursor: no-drop;
}

.bigButton:hover {
  background-color: var(--green-color);
  color: white;
  transition: all 0.2s linear;
}

.processed {
  cursor: wait;
}

.blur {
  background: var(--background-gradient-st);
  filter: blur(5px);
  opacity: 0.8;
  width: 200%;
  height: 200%;
  transform: translate(-25%, -25%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

.popupWrapper {
  max-width: 500px;
  z-index: 20;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;

  opacity: 1;

  @media (max-width: 430px) {
    width: calc(100% - 30px);
  }
}

.templates {
  font-size: 20px;
  overflow: auto;
  //height: 125px;

  & .templateRow {
    & input {
      width: 200px;
      line-height: 25px;
      display: inline-block;

      border-radius: 5px;
    }
  }

  & span {
    width: 200px;
    display: inline-block;

    padding: 5px;
    border-radius: 5px;

    font-size: 18px;
    cursor: pointer;
  }

  & span:hover {
    background-color: var(--green-color);
    color: white;
  }

  & .deleteTemplateButton {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;

    font-size: 18px;
    cursor: pointer;
  }

  & .deleteTemplateButton:hover {
    background-color: var(--orange-color);
    color: white;
  }

  & .renameTemplateButton {
    display: inline-block;
    padding: 5px;
    border-radius: 5px;

    font-size: 18px;
    cursor: pointer;
  }

  & .renameTemplateButton:hover {
    background-color: var(--blue-color);
    color: white;
  }
}

.overflowBlock {
  overflow: auto;
  height: 150px;
}

.subTitle {
  padding-left: 20px;
  font-size: 24px;

  @media (max-width: 960px) {
    padding-left: 0;
  }
}

.formBlock {
  display: block;
  font-size: 20px;
  width: 400px;


  & input {
    border-radius: 5px;
  }

  & textarea {
    border-radius: 5px;
    width: 370px;
    min-height: 100px;
    resize: none;
  }

  & .formRow {
    display: flex;
    justify-content: left;
    margin: 10px 0;

    & div {
      text-align: left;
    }

    & :nth-child(1) {
      min-width: 200px;
    }
  }
}

.templatesViewBlock {
  text-align: left;
  border: 1px solid var(--border-default);
  padding: 5px;

  //background-color: var(--light-grey-font);
  //color: white;
}

.textCounter {
  font-size: 12px;
  cursor: default;
  text-align: left;
}

.blockTitle {
  flex: 1 1 60%;
  padding-right: 24px;
}

.defaultInput {
  flex: 0 0 40%;
  font-family: 'PF BeauSans Pro';
  max-width: 120px;
  font-size: 16px;
  background: transparent;
  border: 1px dotted var(--main-font-color);
  padding: 9px 24px;
  border-radius: 5px;
  color: var(--main-font-color);

  &.error {
    color: red;
  }

  @media (max-width: 600px) {
    margin-top: 24px;
  }

  &.noPadding {
    padding: 0 24px !important;
    cursor: pointer;
  }
}

.button {
  padding: 4px 10px 6px;
  border-radius: 10px;
  background: var(--orange-color);
  font-family: 'PF BeauSans Pro';
  color: #fff;
  border: unset;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: 960px) {
    margin-left: 20px;
    font-size: 16px;
  }

  &:hover {
    background: #323232;
    color: #fff;
    border: unset;
  }
}

.errorMes {
  position: absolute;
  color: red;
  font-size: 12px;

  right: 35px;
  line-height: 35px;
  padding-top: 12px;
  z-index: -1;
}

.mainErrorMes {
  color: red;
  font-size: 12px;

  padding-bottom: 20px;
}

.AddMessage {
  margin: 20px 0;
  color: red;
}