.charts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.chartsSize {
  height: 300px;
}

.selector {
  display: flex;
  font-size: 12px;

  & > div {
    display: flex;
    align-items: center;
    margin: 0 5px;
    padding: 2px 5px;
    border-bottom: 1px dotted black;
    cursor: pointer;
    color: var(--orange-color)
  }

  & > div:hover {
    background-color: var(--orange-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }

  .selected {
    background-color: var(--green-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);

  }

  .noSelected {
    word-break: break-word;
  }

  .subSelector {
    border-bottom: none;
    fill: rgba(0, 0, 0, 0.6);

    & svg {
      fill: rgba(0, 0, 0, 0.6);
    }

  }

  .subSelector:hover {
    background-color: inherit;
    box-shadow: none;
    fill: var(--orange-color)
  }
}

.tooltipElem {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 5px 5px 5px;
  color:white;

  border-radius: 5px;

  & > p {
    padding: 0;
    font-weight: bold;
  }
}

.pad {
  padding: 5px 5px 5px 5px;
}


.subSelector {
  border-bottom: none;
  fill: rgba(0, 0, 0, 0.6);

  & svg {
    fill: rgba(0, 0, 0, 0.6);
  }

}

.subSelector:hover {
  background-color: inherit;
  box-shadow: none;
  fill: var(--orange-color)
}

.chartLabel {
  margin: 10px 0 0 15px;
  text-align: center;
}