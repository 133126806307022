.flex {
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: start;
  }

  @media (max-width: 960px) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 740px) {
    flex-direction: column;
    align-items: start;
  }

  & > *:first-child {
    margin-right: 24px;
  }

  & > * {
    min-height: 100px;
  }
}

.flexSimple {
  display: flex;
  vertical-align: middle;

  & p {
    min-width: 200px;
  }
}

.date {
  display: flex;
  position: relative;
  justify-content: space-around;
  width: 50%;
  color: var(--main-font-color);
  border-radius: 20px;
  padding: 10px 10px 0;
  box-sizing: border-box;
  background: var(--balance-card-background-st);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;

  @media (max-width: 1200px) {
    width: 90%;

    & > *:first-child {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 960px) {
    display: flex;
    position: relative;
    justify-content: space-around;
    width: 320px;
  }

  @media (max-width: 740px) {
    width: 90%;

    & > *:first-child {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 14px;
    width: 100%;
  }
}

.chooseDate {
  padding: 9px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-family: 'PF BeauSans Pro';
  cursor: text;
  line-height: normal;
}

.dateTitle {
  margin-bottom: 10px;
  color: var(--main-font-color);
}

.calendar {
  position: absolute;
  background: var(--balance-card-background-end) !important;
  max-width: 500px !important;
  top: 110%;
  left: 0;
  z-index: 15;

  @media (max-width: 550px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.counterBlock {
  margin: 5px 10px 5px 24px;
  font-size: 16px;
  font-style: italic;
  color: var(--night-color);
}

.filterSettings {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  border-radius: 20px;
  padding: 12px 24px;
  box-sizing: border-box;
  background: var(--balance-card-background-st);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  max-width: 500px;

  & > *:first-child {
    margin-right: 20px;
  }

  & > * {
    margin-bottom: 10px;
  }

  @media (max-width: 1200px) {
    width: 90%;
    justify-content: space-around;
  }

  @media (max-width: 960px) {
    display: flex;
    justify-content: center;
    width: auto;
  }

  @media (max-width: 740px) {
    width: 90%;
    justify-content: space-around;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > *:first-child {
      margin-bottom: 10px;
    }
  }

  & .filterElem > div {
    margin: 10px 0;
  }

  & input {
    border-radius: 5px;
    width: 50px !important;
  }
}