.menuBar {
  border: 1px solid var(--main-border);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 24px;
  margin-top: 24px;
  font-family: 'PF BeauSans Pro';

  @media (max-width: 992px) {
    border: none;
    padding: 0 0 24px;
    margin-top: 0;
  }
}

.smallBubble {
  width: 85px;
  padding: 10px 24px;
}

.toSmallDesignButton {
  width: 20px;
  height: 20px;

  cursor: pointer;

}

.withTooltip:hover > .toolTip {
  display: block !important;
}

.toolTip:hover {
  display: none;
}

.title {
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    margin-bottom: 12px;
  }

  @media (max-width: 450px) {
    margin-bottom: 0;
  }
}

.href {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5rem;

  &.active {
    & > a {
      color: var(--orange-color);
    }
  }

  a {
    color: var(--main-font-color);
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.inline {
  display: flex;
}
