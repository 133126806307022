.slider-navigation {
  position: absolute;
  top: calc(50% - 0.75rem);
  z-index: 1;
  background: url('../../../../img/arrow.svg');
  height: 15.5px;
  width: 11px;
}

.slider-navigation.sliderArrowPrev {
  transform: rotate(-180deg);
  left: -25px;
  cursor: pointer;
}

.slider-navigation.sliderArrowNext {
  right: -25px;
  cursor: pointer;
}
