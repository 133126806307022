.types {
  border: 1px solid var(--border-default);
  height: fit-content;
  border-radius: 10px;
  margin-right: 24px;
  padding: 12px;

  @media (max-width: 850px) {
    flex-direction: row;
    margin-bottom: 24px;
    margin-right: 0;
  }
}

.type {
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease;
  white-space: nowrap;
  background: var(--dark-color);
  color: var(--main-font-color);

  &:hover {
    color: var(--orange-color);
  }

  &:not(:last-child):not(:first-child) {
    margin: 12px 0;
  }
}

.type.active {
  color: var(--orange-color);
}
