.flex {
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    & > *:first-child {
      margin-bottom: 24px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.header > p > a {
  color: var(--main-font-color);

  &:hover {
    text-decoration: underline;
  }
}

.telezonSecurity {
  display: flex;
  align-items: center;

  @media (min-width: 850px) {
    margin-top: 24px;
  }

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }
}

.text {
  margin: 0 24px;
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}

.showBlockB {
  border-top: 1px solid var(--light-color);
  border-bottom: 1px solid var(--light-color);
  color: black;
  text-align: center;

  border-radius: 5px;
}

.showBlockB:hover {
  background-color: var(--orange-color);
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

//.showBlockB {
//  border-top: 1px solid var(--light-color);
//  border-bottom: 1px solid var(--light-color);
//  color: white;
//  text-align: center;
//  background-color: var(--orange-color);
//}
//
//.showBlockB:hover {
//  background-color: var(--background-gradient-dark-end);
//  cursor: pointer;
//  transition: all 0.3s ease;
//}