html {
//  background: url(../../../img/bg_modal_page_new.png) no-repeat center center fixed;
//  -webkit-background-size: cover;
//  -moz-background-size: cover;
//  -o-background-size: cover;
//  background-size: cover;

  font-family: 'PF BeauSans Pro' !important;
}

.loginPage {
  background: url(../../../../img/bg_modal_page_new.png) no-repeat center center fixed;
  -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4444;
}

.flex {
  display: flex;
}

.inputBox {
  margin: 15px 0;
}

.marginTop10 {
  margin-bottom: 20px;

}

.orange {
  background-color: var(--orange-color) !important;
  opacity: 0.8;
  color: white;
}

.green {
  background-color: var(--green-color) !important;
  opacity: 0.8;
  color: white;
}

.grey {
  background-color: var(--light-grey-font) !important;
  opacity: 0.8;
  color: white;
  cursor: progress;
}

.white {

}

.none {

}

.roundButton {
  border-radius: 25px !important;
}

.authBlocks {
  display: flex;
}

.mainBlock {
  position: absolute;
  width: 400px;

  display: flex;
  flex-direction: column;

  & > :last-child {
    margin-top: auto;
  }

  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  z-index: 0;

  border: 3px solid var(--border-default);
  border-radius: 25px;
  background-color: white;

  min-width: 350px;
  height: 650px;
  box-sizing: border-box;
  background: var(--balance-card-background-st);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  & .logo {
      width: 160px;
      height: 100px;
      background-image: url(../../../../img/modal_logo.png);
      background-repeat: no-repeat;
      background-size: cover;

      margin: 0 auto 35px;
    }

  & input {
    border: 1px solid var(--border-default);
    transition: all .2s linear;
    border-radius: 5px;
  }

  & input:hover {
    border: 1px solid var(--dark-border);
  }
}

.mainBlockRegister {
  height: auto !important;
  width: 450px;
  margin: 10px;
  padding: 15px 45px;

  & .formBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    margin: 15px 0;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  & .blockTitle {
    display: table-cell;
    vertical-align: middle;
  }

  & .defaultInput {
    flex: 0 0 40%;
    font-family: 'PF BeauSans Pro';
    max-width: 350px;
    font-size: 14px;
    background: transparent;
    border: 1px dotted var(--main-font-color);
    padding: 5px 24px;
    border-radius: 5px;
    color: var(--main-font-color);

    &.error {
      color: red;
      animation: 1.2s ease-in-out 0s normal none trambling-animation;
    }

    @media (max-width: 600px) {
      margin-top: 24px;
    }

    &.noPadding {
      padding: 0 24px !important;
      cursor: pointer;
    }
  }
}

.registerForms {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  input[type="text"] {
    font-size: 16px;
    width: calc(100% - 10px);
    height: 35px;
    margin: 20px 0;
    padding-left: 10px;
  }
}

.label {
  text-align: center;
  color: var(--main-font-color);

  width: 99%;
}

.authBlock {
  padding: 15px;
  margin: 10px;

  //background: var(--balance-card-background-st);
  //border-radius: 20px;
  //
  //box-sizing: border-box;
  //background: var(--balance-card-background-st);
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  & input {
    font-size: 16px;
    height: 35px;
    width: calc(100% - 15px);

    margin: 5px 0;
    padding-left: 10px;
  }

  & input[type="submit"] {
    width: 100%;
    margin: 5px 0 0 0;
    height: 45px;
    background: #fff;
  }

  & input[type="submit"]:hover {
    cursor: pointer;
  }
}

.authDomainBlock {
  & input[name="login"] {
    width: 69% !important;
  }

  & input[name="domain"] {
    position: absolute;
    width: 20%;
    margin-left: 5px;
  }
}

.registerBlock {
  width: 260px;

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
}

.sberButton {
  border: 1px solid var(--border-default);
  transition: all .2s linear;
  border-radius: 25px;

  line-height: 35px;
  cursor: pointer;
}

.buttonSubmit {
  border: 1px solid var(--border-default);
  transition: all .2s linear;
  border-radius: 5px;

  height: 35px;
  padding: 5px 0;

  line-height: 35px;
  text-align: center;
  cursor: pointer;

  margin: 5px 0;
}

.inputSubmit {
  width: 100%;
  line-height: 0;
  height: 45px;

  font-family: 'PF BeauSans Pro' !important;
  font-size: 16px;
}

.buttonSubmit:hover {
  border: 1px solid var(--dark-border);
}

.footer {
  width: 100%;
  text-align: center;
  color: var(--light-grey-font);

  a {
    color: var(--blue-color);
    cursor: pointer;
  }

  .offertsText {
    width: 100%;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .helpText {
    font-size: 14px;
    color: var(--light-grey-font);
    cursor: pointer;
  }

  .helpText:hover {
    color: var(--blue-color);
  }

}

.esiaIdIco {
  width: 110px;
  height: 24px;
  background-image: url(../../../../img/icons/logo.d173a793363aa9157783.svg);
  background-repeat: no-repeat;
  background-size: contain;

  margin: 15px auto;
}

.noAvailableB {
  cursor: default;
}

.noAvailableB:hover {
  border: 1px solid var(--border-default);
}

.notValidatedIco {
  //animation: move 0.5s;
  //animation-direction: reverse;

  position: absolute;
  margin-left: calc(100% - 110px);

  width: 17px;
  height: 17px;
  border-radius: 50%;

  font-size: 14px;
  color: white;
  background-color: var(--orange-color);
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: var(--green-color);
  }

  &:hover > .helpMess {
    display: block;
  }
}

.validatedIco {
  font-size: 10px;
  line-height: 16px;
  background-color: var(--green-color) !important;
}

.helpMess {
  display: none;
  position: relative;
  z-index: 9999;
  width: 150px;
  background: var(--background-gradient-st);
  color: var(--main-font-color);
  padding: 12px;
  border-radius: 10px;
  border: 1px solid var(--border-default);
  box-shadow: 0 0 20px 0 var(--border-default);

  font-size: 16px;
}

.validateInput {
  position: absolute;
  margin-left: calc(100% - 140px);

  & input {
    width: 40px;
    border: 1px solid var(--main-border-dark);
    animation: 1.2s ease-in-out 0s normal none infinite trambling-animation;
  }

  & input:current {
    animation: none;
  }
}

@keyframes move {
  from {
    margin-left: calc(100% - 110px);
  }
  to {
    margin-left: calc(100% - 120px);
  }
}

@-webkit-keyframes move {
  from {
    margin-left: calc(100% - 110px);
  }
  to {
    margin-left: calc(100% - 120px);
  }
}

@keyframes trambling-animation {
  0%, 50%, 100% {
    transform: rotate(0deg);
  }
  10%, 30% {
    transform: rotate(-10deg);
  }
  20%, 40% {
    transform: rotate(10deg);
  }
}

.buttonsTop {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 5px;
  }
}

.dogovorTextField {

}

.noOrgMessage {
  display: flex;
  flex-direction: column;

  p {
    margin: 10px 0;
    text-align: center;
  }
}

.aButton {
  display: inline-block;
  width: 100%;
}
