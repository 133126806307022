.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  color: var(--night-color);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: all 0.3s ease;

  &.active {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
}

.blur {
  background: var(--background-gradient-st);
  filter: blur(5px);
  opacity: 0.8;
  width: 200%;
  height: 200%;
  transform: translate(-25%, -25%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

.popupWrapper {
  max-width: 400px;
  z-index: 20;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;

  @media (max-width: 430px) {
    width: calc(100% - 30px);
  }
}

.title {
  font-size: 16px;
  margin-bottom: 24px;
  font-weight: bold;
  font-family: 'PF BeauSans Pro';
}

.icons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 400px) {
    justify-content: start;
  }

  & > span {
    width: 60px;
    font-family: 'PF BeauSans Pro';

    @media (max-width: 400px) {
      display: none;
    }
  }

  & > span > img {
    width: 100%;
    height: 100%;
  }
}

.text {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  & > *:first-child {
    margin-right: 12px;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
}

.textTitle {
  font-size: 16px;
}

.textDescription {
  font-size: 14px;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted var(--border-default);
  border-radius: 5px;
  margin-bottom: 24px;
  font-family: 'PF BeauSans Pro';
  background: transparent;
}

.btn {
  display: block;
  text-align: center;


  & > button {
    padding: 4px 10px 6px;
    background: var(--orange-color);

    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    cursor: pointer;
    font-family: 'PF BeauSans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    transition: all 0.3s ease;

    a {
      color: white;
    }

    &:hover {
      background: #323232;
    }

    @media (max-width: 450px) {
      margin-bottom: 24px;
    }
  }
}

.link {
  text-align: center;
  margin: 10px 0;
  font-family: 'PF BeauSans Pro';

  & > a {
    color: var(--night-color);
    font-size: 12px;
    text-decoration: underline;
  }
}

.visa {
  width: 60px;
  margin-right: 24px;
  cursor: pointer;

  & img {
    display: block;
  }
}

.mastercard {
  width: 60px;
  margin-right: 24px;
  cursor: pointer;
  & img {
    display: block;
  }
}

.mir {
  width: 60px;
  cursor: pointer;
  & img {
    display: block;
  }
}

.wrongText {
  color: red;
  text-align: center;
}
