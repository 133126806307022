.realCheckBox {
  display: none;
}

.fakeCb {
  position: relative;
  width: 46px;
  height: 24px !important;

  background-color: #ebebeb;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dbdbdb;
  border-radius: 18px;

}

.fakeCb::before {
  content: "";

  width: 24px !important;
  height: 24px !important;
  margin: -1px 0 0 -1px;

  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 18px;

  transition: 0.2s ease-in;
  float: left;
}

.realCheckBox:checked + .fakeCb::before {
  float: right;
  background-color: var(--blue-color);
  opacity: 0.5;
}
