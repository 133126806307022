.title {
  font-size: 22px;
  color: var(--main-font-color);
  margin-bottom: 10px;
}

.filterWrapper {
  display: grid;
  grid-template: 'fr fr fr fr';

  @media (max-width: 1300px) {
    grid-template: 'fr fr fr';
  }

  @media (max-width: 650px) {
    grid-template: 'fr fr';
  }

  @media (max-width: 470px) {
    display: flex;
    flex-direction: column;

    & > * {
      padding: 5px 0;
    }
  }
}

.menuBurger {
  margin-top: 5px;
  img {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 961px) {
    display: none;
  }

  @media (max-width: 470px) {
    margin-right: 24px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}
