.main{

}

// таблица

.headBlock {
  display: flex;

  & > * {
    margin-right: 25px;
  }

  & > :last-child {
    margin-right: 0;
  }

}

.callsTable {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  .table {
    color: var(--main-font-color);
    text-align: center;
    font-size:0.8rem;
    border-collapse: collapse;

    overflow-x: auto;

    & tr:nth-child(even) {
      background-color: var(--light-color);
      color: var(--night-color);
    }

    .tableHead {
      height: 50px;
      background-color: white;

      & tr {

      }

      & th {
        cursor: pointer;
        padding: 0 20px;

        &:not(:last-child) {
          border-right: 1px solid #e5e5e5;
        }
      }

      & > th > span {
        display: inline-block;
      }
    }

    .darkHeadTheme {
      background-color: var(--background-gradient-dark-end);
    }
  }

  & .tableBody {
    .pointer {
      cursor: pointer;
    }
    & tr {
      & td {
        height: 34px;
        padding: 5px;
      }
    }

    .tableFilter > * {
      background-color: var(--diagramma-background-light);

      @media (max-width: 1400px) {
        //width: 94px;
      }
    }

    .reactSelect input {
      background-color: inherit !important;
    }

    .tableFilterDarkTheme > * {
      background-color: var(--background-gradient-dark-end);
    }

    .tableFilter {
      //display: block;
      height: 25px;
      //padding: 5px 0;

      td {
        height: 15px;
        max-width: 70px;
      }

    }
  }

  .hovered {
    cursor: pointer;
  }

  .hovered:hover > .detailHelp {
    display: flex;
  }

  .hovered:hover > td .callMenu {
    display: flex;
  }

  .withComment {
    background-color: var(--background-input-blue) !important;
    //color: var(--main-font-color)
  }

  .detailHelp {
    position: absolute;
    margin-right: 0;
    margin-left: 100px;

    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    color: white;

    display: none;
    //align-items: center;
    //justify-content: center;
    padding: 10px;
  }

  .callMenu {
    position: absolute;
    margin-right: 0;
    margin-left: 25px;

    background-color: white;
    border-radius: 5px;
    color: white;

    display: none;
    //align-items: center;
    //justify-content: center;
    padding: 10px;

    & .callMenuItems {
      width: 20px;
      height: 20px;
      margin: 0 5px;

    }

    & .callMenuItems:hover > .toolTip {
      display: block !important;
    }
  }
}

.counterBlock {
  margin: 5px 10px 5px 24px;
  font-size: 16px;
  font-style: italic;
  color: var(--night-color);
}
.sortArrow {
  display: inline-block;
}

.rotate {
  transform: rotate(180deg) !important;
}

// Record Tooltip
.record {
  //width: 111px;

  img {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    cursor: pointer;
  }
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}
