.mainPart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 40px;

  & > *:first-child {
    flex: 0 0 calc(20.127% - 24px);
    margin-top: -24px;
  }

  & > *:last-child {
    flex: 0 0 79.5%;
    padding-left: 20px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;

    @media (max-width: 992px) {
      flex: 0 0 100%;
    }
  }
}

.minimalDesign {
  & > *:first-child {
    flex: 0 0 24px !important;
  }

  & > *:last-child {
    flex: 0 0 93% !important;
  }
}

.flex {
  color: var(--main-font-color);
  padding: 24px;
}

.formMain {
  display: flex;
  justify-content: space-between;
}

.formBlockLine {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & input {
    min-width: 100px;
  }

  & p {
    min-width: 100px;
  }


}

.formBlock {
  display: flex;
  justify-content: flex-start;
  padding-left: 40px;
  margin: 24px 0;

  @media (max-width: 960px) {
    padding-left: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.blockTitle {
  min-width: 150px;
  padding-right: 24px;
}

.cards {
  //display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 500px;

  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }

  & > * {
    flex: 0 0 calc(25% - 24px);
    padding: 12px 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 24px 0 0;
    //margin-left: 24px;

    @media (max-width: 1120px) {
      flex: 0 0 calc(50% - 24px);
      margin-bottom: 0;
    }

    @media (max-width: 550px) {
      flex: 0 0 100%;
      margin-left: 0;
    }
  }

  & > * {
    border: 1px solid var(--border-default);
    border-radius: 20px;

    &:hover {
      box-shadow: 0 22px 80px rgba(0, 0, 0, 0.07),
      0 6.63236px 24.1177px rgba(0, 0, 0, 0.0456112),
      0 2.75474px 10.0172px rgba(0, 0, 0, 0.035),
      0 0.996336px 3.62304px rgba(0, 0, 0, 0.0243888);
    }
  }
}

.error {
  background-color: rgba(255, 0, 4, 0.5);
}