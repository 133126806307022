.link {
  color: var(--main-font-color);
  display: block;
  padding: 12px;
  border: 1px dotted transparent;
  border-radius: 10px;
  margin-right: 24px;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 10px;
  }

  &:hover {
    border: 1px dotted var(--main-font-color);
  }
}
