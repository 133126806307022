.showBlockB {
  border-top: 1px solid var(--light-color);
  border-bottom: 1px solid var(--light-color);
  color: black;
  text-align: center;

  border-radius: 5px;

  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.showBlockB:hover {
  background-color: var(--orange-color);
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}