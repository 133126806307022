.wrapper {
  margin-top: 48px;
  width: 90%;
  margin: 48px auto 0;

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
}

.slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
  }
}
