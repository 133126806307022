.main {
  display: flex;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}

.flexFloatLeft {
  margin-right: auto;
}

.flexButtons {
  display: flex;
  flex-direction: row-reverse;

  //& :first-child {
  //  margin-right: auto;
  //}

  & :last-child {
    margin-right: auto;
  }
}

.csvLink {
  margin-left: 20px;
}

.btn {
  padding: 4px 10px 6px;
  background: var(--orange-color);
  color: #fff;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
  min-width: auto;
  cursor: pointer;
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    background: #323232;
    color: #fff;
  }

  @media (max-width: 450px) {
    margin-bottom: 24px;
  }
}

.btnDownload {
  float: right;
  background-color: var(--green-color);
}

.SvgElem {
  font-size: 1em;
  vertical-align: middle;
}


.table {
  color: var(--main-font-color);
  text-align: center;
  font-size:0.8rem;
  border-collapse: collapse;

  overflow-x: auto;

  & tr:nth-child(even) {
    background-color: var(--light-color);
    color: var(--night-color);
  }

  .tableHead {

    & > th {
      cursor: pointer;
      width: 150px;
      height: 50px;

      &:not(:last-child) {
        border-right: 1px solid #e5e5e5;
      }
    }

    & > th > span {
      display: inline-block;
    }


  }

}

.rotate {
  transform: rotate(180deg) !important;
}

.loadingDiv {
  position: relative;
  margin-top: -70px;
  left: 50%;
  right: 50%;
}

.summaryValues {
  margin-left: auto;
}

.tableInactive {
  color: var(--main-font-color);
  text-align: center;
  font-size:0.8rem;
  border-collapse: collapse;

  overflow-x: auto;

  & tr:nth-child(even) {
    background-color: var(--light-color);
    color: var(--night-color);
  }

  & tr {
    opacity: 0.5;
    cursor: none;
  }

  .tableHead {
    height: 80px;

    & > th {
      cursor: none;
      width: 150px;

      &:not(:last-child) {
        border-right: 1px solid #e5e5e5;
      }
    }


  }

}