.window {
  border: 1px solid var(--border-default);
  border-radius: 20px;
  overflow: auto;
  padding: 12px;
  box-sizing: border-box;
  height: 524px;
  min-height: 50px;
  margin-bottom: 24px;

  &::-webkit-scrollbar {
    height: 12px; /* высота для горизонтального скролла */
    background-color: var(--balance-card-background-st);
    border-radius: 9em;
    margin-top: 5px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-font-color);
    border-radius: 9em;
    margin-top: 5px;
  }

  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 24px;
    max-height: 524px;
    height: auto;
  }
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}

.plus {
  border: 1px solid var(--main-font-color);
  padding: 0 6px;
  margin-right: 7px;
  transition: all 0.3s ease;
  border-radius: 50%;
  cursor: pointer;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--background-gradient-st);
    background: var(--main-font-color);
  }
}

.number {
  display: flex;
  margin-top: 8px;
}

.abonentPayment {
  display: flex;
  align-items: center;
}

.abonentParagraph {
  margin-right: 12px;
}

.wrapper {
  @media (min-width: 850px) {
    width: 33%;
  }
}
