.cb_1 {
    width: 46px;
    height: 24px !important;

    background-color: #ebebeb;
    box-shadow: inset 0 4px 0 rgb(0 0 0 / 0.8);
    border: 1px solid #dbdbdb;
    border-radius: 18px;
}

.cb_1.on {
    background-color: var(--blue-color);
    opacity: 0.5;
}

.cb_1 .cbb_1 {
    width: 24px;
    height: 24px !important;
    margin: -1px 0 0 -1px;

    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 18px;
}

.cb_1 .cbb_1.on {
    float: right;
}
