.picker {
  display: flex;
  align-items: center;

  overscroll-behavior-y: contain;
  overflow-y: auto;

  padding: 5px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;

  & .inputText {
    position: absolute;
    width: 46px;
    border-radius: 0;
    font-size: 18px;

  }

  & .udButton {
    text-align: center;
    height: 15px;
    width: 100%;

    cursor: pointer;
    margin: 2px 0;

    & span {
      position: relative;
      top: 2px;
    }

  }

  & .udButton:hover {
    color: var(--orange-color);
  }

  & .valueItem {
    cursor: pointer;
    line-height: 12px;
    padding: 0;
  }

  & > span {
    padding: 0 5px;
  }

}

.rotate {
  transform: rotate(180deg);
}