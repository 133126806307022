.player {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;

    .controls {
        flex-grow: 1;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .player__button {
        width: fit-content;
        margin-bottom: 15px;
        background-color: transparent;
        border: none;

        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
            svg {
                color: var(--orange-color);
            }
        }

        svg {
            font-size: 3em;
            border: 1.5px solid var(--orange-color);
            border-radius: 50%;
            color: black;
        }
    }

    .player__download {
        width: fit-content;
        background-color: transparent;
        border: none;

        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
            svg {
                color: var(--orange-color);
            }
        }

        svg {
            font-size: 2em;
            border-radius: 50%;
            color: black;
        }
    }

    .bar {
        user-select: none;
        width: 100%;
        display: flex;
        align-items: center;

        input[type='range'] {
            width: 100%;
            appearance: auto;
            cursor: default;
            background-color: (var(--border-default), rgb(255, 255, 255));
            padding: initial;
            border: initial;
            margin: 2px;

            overflow: hidden;
            //-webkit-appearance: none;
            //background-color: var(--orange-color);
            //border: 1px solid var(--border-default);
        }


        //
        //input[type='range']::-webkit-slider-runnable-track {
        //    //height: 10px;
        //    -webkit-appearance: none;
        //    color: var(--orange-color);
        //    margin-top: -1px;
        //}


        .barTime {
            color: black;
            font-size: 16px;
            margin: 0 10px;
        }

        .bar__progress {
            flex: 1;
            border-radius: 5px;
            margin: 0 20px;
            height: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .bar__progress__knob {
                position: relative;
                height: 16px;
                width: 16px;
                border: 1.5px solid var(--orange-color);;
                border-radius: 50%;
                background-color: orange;
            }
        }
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;

        .controls {
            width: 100%;
            margin-top: 20px;
        }

        .bar {
            width: 90%;
        }
    }

    @media screen {
        .song {
            min-width: 280px;
            .songTitle {
                font-size: 1.2em;
            }

            .songArtist {
                font-size: 0.8em;
            }
        }
    }
}
