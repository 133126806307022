
.mainPart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 40px;

    & > *:first-child {
        flex: 0 0 calc(20.127% - 24px);
        margin-top: -24px;
    }

    & > *:last-child {
        flex: 0 0 79.5%;
        padding-left: 20px;
        border: 1px solid var(--border-default);
        box-sizing: border-box;
        border-radius: 20px;

        @media (max-width: 992px) {
            flex: 0 0 100%;
        }
    }
}

.minimalDesign {
    & > *:first-child {
        flex: 0 0 24px !important;
        margin-left: 0 !important;
    }

    & > *:last-child {
        flex: 0 0 93% !important;

        border: none;
        box-sizing: border-box;
        border-radius: 0;
    }
}

.flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.formFlex {
    & > :last-child {
       float: right;
    }
}

.noBlubble {
    color: var(--main-font-color);
    padding: 24px;

    box-sizing: border-box;
    border-radius: 20px;
}

.bubble {
    color: var(--main-font-color);
    padding: 24px;

    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;
    //margin-bottom: 24px;
    margin-right: 24px;
}

.fixedBubble {
    //width: 600px;
    display: flex;
    flex-direction: column;

    //justify-content: space-between
}

.title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 24px;
    width: 100%;
}

.formBlock {
    display: flex;
    justify-content: center;
    //justify-content: space-between;
    padding-left: 40px;
    margin: 24px 0;

    @media (max-width: 960px) {
        padding-left: 0;
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.blockTitle {
    flex: 1 1 20%;
    padding-right: 24px;
}

.tableForm {
    td > label {
        display: flex;
        justify-content: center;
    }

    .spacer {
        height: 20px;
    }

    .newString > td > input {
        color: var(--light-grey-font);
        border-color: var(--light-grey-font);
    }
}

.amoSettings {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid black;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > :last-child {
        float: right;
        width: 200px;
        align-self: end;
    }
}

.amoTable {

    td {
        color: red !important;
    }

}

.plusLine {
    text-align: center;
    width: 25px;
    line-height: 25px;

    cursor: pointer;

    border: 1px solid var(--orange-color);
    background-color: var(--orange-color);
    color: white;
    border-radius: 10px;

    margin: 10px;

    &:hover {
        background-color: var(--blue-color);
        border: 1px solid var(--blue-color);
    }

    &:hover::after {
        content: attr(data-title);

        position: absolute; /* Абсолютное позиционирование */
        //left: 20%; top: 30%; /* Положение подсказки */
        margin-left: 10px;
        z-index:999; /* Отображаем подсказку поверх других элементов */
        background: var(--background-gradient-st); /* Полупрозрачный цвет фона */
        color: var(--main-font-color);

        border: 1px solid var(--border-default);
        border-radius: 10px;
        padding: 5px 5px;
    }
}

.tableInput {
    flex: 1 1 20%;
    font-family: 'PF BeauSans Pro';
    max-width: 80px;
    //font-size: 16px;
    background: transparent;
    border: 1px dotted var(--main-font-color);
    padding: 5px 5px;
    border-radius: 5px;
    color: var(--main-font-color);

    &.error {
        color: red;
    }

    @media (max-width: 600px) {
        margin-top: 24px;
    }

    &.noPadding {
        padding: 0 24px !important;
        cursor: pointer;
    }
}

.amoInput {
    max-width: 100% !important;
    width: 500px !important;
}

.textArea {
    flex: 0 0 40%;
    font-family: 'PF BeauSans Pro';
    min-width: 350px !important;
    min-height: 20px !important;
    font-size: 16px;

    border: none;
    background-color: var(--lightgreen-20);
}

.defaultInput {
    flex: 0 0 60%;
    font-family: 'PF BeauSans Pro';
    max-width: 200px;
    font-size: 16px;
    background: transparent;
    border: 1px dotted var(--main-font-color);
    padding: 9px 24px;
    border-radius: 5px;
    color: var(--main-font-color);

    &.error {
        color: red;
        //padding-left: 35px;
        //box-sizing: border-box;
    }

    @media (max-width: 600px) {
        margin-top: 24px;
    }

    &.noPadding {
        padding: 0 24px !important;
        cursor: pointer;
    }
}

.realCheckBox {
    display: none;
}

.fakeCb {
    position: relative;
    width: 46px;
    height: 24px !important;

    background-color: #ebebeb;
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid #dbdbdb;
    border-radius: 18px;

}

.fakeCb::before {
    content: "";

    width: 24px !important;
    height: 24px !important;
    margin: -1px 0 0 -1px;

    background-color: #ffffff;
    border: 1px solid #dbdbdb;
    border-radius: 18px;

    transition: 0.2s ease-in;
    float: left;
}

.realCheckBox:checked + .fakeCb::before {
    float: right;
    background-color: var(--blue-color);
    opacity: 0.5;
}


.bubbleAnimation {
    position: relative;
    z-index: 1000;
    //width: 100% !important;

    //display: flex;
    //flex-direction: column;
    //align-items: flex-end;
}

.remark {
    max-width: 280px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
}

.borderedInput {
    border: 1px solid var(--blue);
    border-radius: 10px;
}
