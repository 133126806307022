.bubbles {
  display: flex;

  & > :first-child {
    margin-right: 20px;
  }

  & > * {
    height: 120px;
  }
}