.elemBlock {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}