.card {
  text-align: center;
  color: var(--main-font-color);
  transition: all 0.3s ease;
  cursor: pointer;

  max-width: 195px;
  min-height: 275px;

  & > .btn {
    margin: 10px 0;
    border-radius: 5px;
    color: #a3a3a3;

    cursor: pointer;
  }

  &:hover {
    //background: var(--main-font-color);
    //color: var(--background-gradient-st);
    transform: scale(1.05);

    & > .btn {
      background: #fe4f1a;
      color: #000;
    }

    & > .btn:hover {
      background: var(--green-color);
    }
  }

  &.active {
    background: var(--main-font-color);
    color: var(--background-gradient-st);
    transform: scale(1.05);

    & > .btn {
      background: #fe4f1a;
      color: #000;
    }

    & > .btn:hover {
      background: var(--green-color);
    }
  }
}

.cardCurrent {
  text-align: center;
  color: var(--main-font-color);
  background: var(--balance-card-background-dark-grey);
  transition: all 0.3s ease;
  cursor: pointer;

  max-width: 195px;

  & > button:disabled {
    margin: 10px 0;
    cursor: pointer;

    background: var(-internal-light-dark);
    color: var(-internal-light-dark);
  }
}

.card:hover > .help {
  display: block;

}

.help {
  position: absolute;
  display: none;
  width: 100%;

  top: -10px;

  background: var(--background-gradient-st);
  color: var(--main-font-color);
  padding: 12px;
  border-Radius: 10px;
  border: 1px solid var(--border-default);
  //box-shadow: 0 0 20px 0 var(--border-default);
}

.wrapper {
  flex: 1 1 auto;
}

.title {
  margin-bottom: 12px;
  color: inherit;
}

.price {
  margin: 12px 0;
  color: inherit;
}

.cost {
  font-size: 36px;
  padding-bottom: 12px;
  color: inherit;
  font-weight: 700;
}

.paragraph {
  font-size: 14px;
}
