.window {
  border: 1px solid var(--border-default);
  border-radius: 20px;
  margin-right: 24px;
  padding: 12px 0 12px 12px;
  box-sizing: border-box;

  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 24px;
  }
}

.plus {
  border: 1px solid var(--main-font-color);
  margin-right: 12px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--background-gradient-st);
    background: var(--main-font-color);
  }
}

.number {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}

.wrapper {
  height: 450px;
  margin-bottom: 24px;
  padding-right: 12px;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 12px; /* высота для горизонтального скролла */
    background-color: var(--balance-card-background-st);
    border-radius: 9em;
    margin-top: 5px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-font-color);
    border-radius: 9em;
    margin-top: 5px;
  }

  @media (max-width: 850px) {
    max-height: 450px;
    height: auto;
  }
}

.reload {
  background: var(--orange-color);
  color: #fff;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: var(--night-color);
    color: #fff;
  }
}

.flex {
  display: flex;
  flex-direction: column;

  @media (min-width: 850px) {
    width: 55%;
  }
}

.telezonSecurity {
  display: flex;
  align-items: center;

  @media (min-width: 850px) {
    margin-top: 24px;
  }

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }
}

.text {
  margin: 0 24px;
}
