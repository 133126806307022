.main {
  margin: 25px 0;
}

.charts {
  display: flex;
  justify-content: space-between;

  .legend {

  }
}

.selector {
  display: flex;

  font-size: 12px;

  & > div {
    margin: 0 5px;
    padding: 2px 5px;
    border-bottom: 1px dotted black;
    cursor: pointer;
  }

  & > div:hover {
    background-color: var(--orange-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);
  }

  .selected {
    background-color: var(--orange-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);

  }

  .selectedSub {
    background-color: var(--green-color);
    color: white;
    box-sizing: border-box;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.1);

  }

  .noSelected {
    word-break: break-word;
  }
}

.tooltipElem {
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 5px 5px 5px;
  color:white;

  border-radius: 5px;

  & > p {
    font-weight: bold;
  }
}

.lineList {
  height: 280px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  font-size: 12px;


  .table {
    text-align: center;
    font-size:0.7rem;
    border-collapse: collapse;

    & th {
      padding: 0 2px;
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    & tr {
      height: 50px;
    }

    & td {
      padding: 0 2px;
    }

    & tr:nth-child(even) {
      background-color: var(--light-color);
      color: var(--night-color);
    }

    & td {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.chartBig {
  //overflow-y: auto;

}

.chartLabel {
  margin: 10px 0 0 15px;
  text-align: center;
}