.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  color: var(--night-color);
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);
  transition: all 0.3s ease;

  &.active {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
}

.blur {
  background: var(--background-gradient-st);
  filter: blur(5px);
  opacity: 0.8;
  width: 200%;
  height: 200%;
  transform: translate(-25%, -25%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

.popupWrapper {
  max-width: 500px;
  z-index: 20;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 10px;

  @media (max-width: 430px) {
    width: calc(100% - 30px);
  }
}

.subTitle {
  padding-left: 20px;
  font-size: 24px;

  @media (max-width: 960px) {
    padding-left: 0;
  }
}

.formBlock {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  margin: 24px 0;

  @media (max-width: 960px) {
    padding-left: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.blockTitle {
  flex: 1 1 60%;
  padding-right: 24px;
}

.defaultInput {
  flex: 0 0 40%;
  font-family: 'PF BeauSans Pro';
  max-width: 120px;
  font-size: 16px;
  background: transparent;
  border: 1px dotted var(--main-font-color);
  padding: 9px 24px;
  border-radius: 5px;
  color: var(--main-font-color);

  &.error {
    color: red;
  }

  @media (max-width: 600px) {
    margin-top: 24px;
  }

  &.noPadding {
    padding: 0 24px !important;
    cursor: pointer;
  }
}

.button {
  padding: 4px 10px 6px;
  border-radius: 10px;
  background: var(--orange-color);
  font-family: 'PF BeauSans Pro';
  color: #fff;
  border: unset;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: 960px) {
    margin-left: 20px;
    font-size: 16px;
  }

  &:hover {
    background: #323232;
    color: #fff;
    border: unset;
  }
}

.errorMes {
  position: absolute;
  color: red;
  font-size: 12px;

  right: 35px;
  line-height: 35px;
  padding-top: 12px;
  z-index: -1;
}

.mainErrorMes {
  color: red;
  font-size: 12px;

  padding-bottom: 20px;
}

.AddMessage {
  margin: 20px 0;
  color: red;
}