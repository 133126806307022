.page {
    padding: 3px 0;
    cursor: pointer;
    margin: 0 3px;
    display: inline-flex;
    padding: 10px;
    border-radius: 10px;
    color: #333;
    background-color: var(--light-color);
    transition: all 0.3s ease;

&:hover {
     color: var(--light-color);
     background: var(--orange-color);
 }
}

.active {
    color: var(--light-color);
    background: var(--orange-color);
}

.pages {
    padding: 24px;
}

.pages div {
    padding: 24px;
    display: inline-block;
}

.etc {
    padding: 10px 24px !important;

}