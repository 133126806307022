.buttonWithText {

    & > * {
        margin: 10px 0;
    }

    .selectionButton {
        cursor: pointer;

        font-size: 1.5rem;
        font-weight: 400;
        text-transform: none;

        border-radius: 0.5rem;

        height: 90px !important;
        background: var(--lightblue);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 0;
        outline: 0;
        transition: all 0.3s ease;

        line-height: 1.5 !important;
        width: 100% !important;
    }

    .activeButton {
        background: var(--blue-color) !important;
        color: var(--local-white-color) !important;
    }

    .inactive {
        background: var(--light-grey-font);
        color: var(--local-white-color);
    }

    :hover::after {
        content: attr(data-title); /* Выводим текст */
        position: absolute; /* Абсолютное позиционирование */
        margin-left: 70px;
        margin-top: 90px;

        background: var(--background-gradient-st);
        color: var(--main-font-color);
        padding: 12px;
        border-radius: 10px;
        border: 1px solid var(--border-default);
        box-shadow: 0 0 20px 0 var(--border-default);

        z-index: 999999; /* Отображаем подсказку поверх других элементов */
        //font-family: Arial, sans-serif; /* Гарнитура шрифта */
        font-size: 16px; /* Размер текста подсказки */
        line-height: 1.5rem;
    }
}

