.errorsBlock {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 999;
}

.errorMessage {
    padding: 10px;
    width: 200px;
    min-height: 100px;
    background-color: rgba(255, 0, 0, 0.80);
    color: white;

    border: 1px solid var(--border-default);
    border-radius: 10px;
}

.xButton {
    cursor: pointer;
    &:hover {
        color: green;
    }
}

.title {
    display: flex;
    justify-content: space-between;

    text-transform: uppercase;

    padding-bottom: 10px;;
}