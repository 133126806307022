.telezonSecurity {
  display: flex;
  align-items: center;

  @media (min-width: 850px) {
    margin-top: 24px;
  }

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }
}

.text {
  margin: 0 24px;
}

.mark {
  border: 1px solid var(--main-font-color);
  border-radius: 50%;
  padding: 2px 10px;
  cursor: pointer;
}