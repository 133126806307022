.mark {
    border: 1px solid var(--main-font-color);
    border-radius: 50%;
    padding: 2px 10px;
    cursor: pointer;
}

.whiteMark {
    border: 1px solid white;
    border-radius: 50%;
    padding: 2px 10px;
    cursor: pointer;
}