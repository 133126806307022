.callTable {
  font-size: 18px;
  text-align: left;
  margin-right: 10px;

  & tr {
    height: 50px;
  }
}

.numberInput {
  border-radius: 5px;
  width: 150px;

}

.bigButton {
  width: 100%;
  height: 80px;
  margin-top: 10px;
  font-size: 20px;

  border: 1px solid var(--border-default);
  border-radius: 5px;
  line-height: 80px;

  cursor: pointer;
}

.bigButton:hover {
  background-color: var(--green-color);
  color: white;
  transition: all 0.2s linear;
}

.bigStatusGreen {
  width: 100%;
  height: 80px;

  border: 1px solid var(--border-default);
  color: white;
  border-radius: 5px;
  line-height: 80px;
  background-color: var(--green-color);

}

.bigStatusRed {
  width: 100%;
  height: 100px;

  border: 1px solid var(--border-default);
  border-radius: 30px;
  line-height: 100px;
  background-color: var(--orange-color);

}