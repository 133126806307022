// font colors
$header-font-dark: #fff;
$header-font-light: #231f20;
$main-font-dark: #e5e5e5;
$main-font-light: #231f20;
$font-light-grey: #a3a3a3;

// input
$input-main-blue: rgba(0, 150, 217, 0.54);

// buttons
$button-text-color-dark: rgb(85, 85, 85);

// pincode btn
$pin-btn-dark: #0096d9;
$pin-btn-light: #ffffff;

// pincode background
$pin-background-dark: transparent;
$pin-background-light: #0096d9;

// pincode background
$pin-border-light: transparent;
$pin-border-dark: #0096d9;

// header line
$line-light: #e5e5e5;
$line-dark: #fff;

// balance background
$balance-background-dark-st: #323232;
$balance-background-dark-end: #323232;
$balance-background-light-st: #fff;
$balance-background-light-end: #f0f0f0;

// cards background
$balance-card-background-light-st: #fff;
$balance-card-background-light-end: #f0f0f0;
$balance-card-background-dark-st: #231f20;
$balance-card-background-dark-end: #231f20;
$balance-card-background-dark-grey: #c1c1c1;

// body background
$start-gradient-light: #f0f0f0;
$end-gradient-light: #fff;
$start-gradient-dark: #272727;
$end-gradient-dark: #272727;

// border cards
$light-border: #e5e5e5;
$dark-border: #231f20;

// cash border
$cash-border-light: #29a53d;
$cash-border-dark: #231f20;

// borders
$main-border-light: #e5e5e5;
$main-border-dark: #231f20;

// diagramma text colors
$diagramma-font-light: #231f20;
$diagramma-font-dark: #e5e5e5;

// diagramma background colors
$diagramma-background-light: #ffc1af;
$diagramma-background-dark: #231f20;

// orange text
$orange-text-light: #fe4f1a;
$orange-text-dark: #e5e5e5;

// dark => light
$text-other-light: #231f20;
$text-other-dark: #fff;

// static colors
$night-color: #231f20;
$light-color: #e5e5e5;
$orange-color: #fe4f1a;
$green-color: #29a53d;
$blue-color: #0096d9;
$lightblue: rgba(0,150,217,.1);
$localwhite: #fff;

:root {
  // body background
  --background-gradient-light-end: #{$end-gradient-light};
  --background-gradient-light-start: #{$start-gradient-light};
  --background-gradient-dark-end: #{$end-gradient-dark};
  --background-gradient-dark-start: #{$start-gradient-dark};

  --background-gradient-st: var(#{--background-gradient-light-start});
  --background-gradient-end: var(#{--background-gradient-light-end});

  // text colors
  --header-font-dark: #{$header-font-dark};
  --header-font-light: #{$header-font-light};
  --main-font-dark: #{$main-font-dark};
  --main-font-light: #{$main-font-light};

  --header-font-color: var(#{--header-font-light});
  --main-font-color: var(#{--main-font-light});

  --light-grey-font: #{$font-light-grey};

  // pin text colors
  --pin-btn-dark: #{$pin-btn-dark};
  --pin-btn-light: #{$pin-btn-light};

  --pin-btn-color: var(#{--pin-btn-light});

  // pin background colors
  --pin-background-light: #{$pin-background-light};
  --pin-background-dark: #{$pin-background-dark};

  --pin-background-color: var(#{--pin-background-light});

  // pin border colors
  --pin-border-light: #{$pin-border-light};
  --pin-border-dark: #{$pin-border-dark};

  --pin-border-color: var(#{--pin-border-light});

  // background balance gradient
  --balance-background-dark-st: #{$balance-background-dark-st};
  --balance-background-dark-end: #{$balance-background-dark-end};
  --balance-background-light-st: #{$balance-background-light-st};
  --balance-background-light-end: #{$balance-background-light-end};

  --balance-background-st: var(#{--balance-background-light-st});
  --balance-background-end: var(#{--balance-background-light-end});

  // background cards gradient
  --balance-card-background-dark-st: #{$balance-card-background-dark-st};
  --balance-card-background-dark-end: #{$balance-card-background-dark-end};
  --balance-card-background-light-st: #{$balance-card-background-light-st};
  --balance-card-background-light-end: #{$balance-card-background-light-end};
  --balance-card-background-dark-grey: #{$balance-card-background-dark-grey};

  --balance-card-background-st: var(#{--balance-card-background-light-st});
  --balance-card-background-end: var(#{--balance-card-background-light-end});

  // border cards (balance)

  --light-border: #{$light-border};
  --dark-border: #{$dark-border};

  --border-default: var(--light-border);

  // cash border

  --cash-border-light: #{$cash-border-light};
  --cash-border-dark: #{$cash-border-dark};

  --cash-border: var(--cash-border-light);

  // main border

  --main-border-light: #{$main-border-light};
  --main-border-dark: #{$main-border-dark};

  --main-border: var(--main-border-light);

  // diagramma font color
  --diagramma-font-light: #{$diagramma-font-light};
  --diagramma-font-dark: #{$diagramma-font-dark};

  --diagramma-font-default: var(--diagramma-font-light);

  // diagramma background color
  --diagramma-background-light: #{$diagramma-background-light};
  --diagramma-background-dark: #{$diagramma-background-dark};

  --diagramma-background-default: var(--diagramma-background-light);

  // orange text

  --orange-text-light: #{$orange-text-light};
  --orange-text-dark: #{$orange-text-dark};

  --orange-text-default: var(--orange-text-light);

  // dark => Light

  --text-other-light: #{$text-other-light};
  --text-other-dark: #{$text-other-dark};

  --text-other-default: var(--text-other-light);

  // static colors
  --night-color: #{$night-color};
  --light-color: #{$light-color};
  --orange-color: #{$orange-color};
  --green-color: #{$green-color};
  --blue-color: #{$blue-color};
  --lightblue: #{$lightblue};
  --local-white-color: #{$localwhite};

  // input styles
  --background-input-blue: #{$input-main-blue};
  --buttons-text-color-dark: #{$button-text-color-dark};
  --lightgreen-20: rgba(41,165,61,.2);
}
