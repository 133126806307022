@import '../../../variables.scss';

.fullWidth {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding: 24px;
  z-index: -1;
  transition: z-index 0.3s ease;

  &.act {
    z-index: 60;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.popupMenu {
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translate(-100%, 0);
  z-index: -1;
  background: linear-gradient(
    61deg,
    var(--background-gradient-end),
    var(--background-gradient-st)
  );
  transition: all 0.5s ease;
  overflow-y: auto;

  &.active {
    transform: translate(0, 0);
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  @media (max-width: 992px) {
    width: 100vw;
    height: 100vh;
    right: 0;
    padding-left: 10px;
  }

  @media (max-width: 500px) {
    padding-top: 55px;
  }
}

.none {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.link {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 25.89px;
  line-height: 150%;
  text-align: center;

  &:first-child {
    margin-top: 25px;
  }

  @media (max-width: 992px) {
    text-align: left;
    margin: 10px 0;
  }
}

.color {
  color: var(--header-font-color);
}

.cross {
  background: transparent;
  position: absolute;
  top: 28px;
  right: 20px;
  border: none;

  & > img {
    width: 34px;
  }
}

.darkCross svg {
  fill: #fff;
}

.rightSide {
  display: flex;
  align-items: center;
}

.features {
  display: flex;
  align-items: center;
  border-right: 1px solid $line-light;
  margin-top: 20px;

  p,
  a {
    margin-right: 20px;
    width: 20px;
    height: 20px;
  }

  @media (max-width: 992px) {
    display: grid;
    grid-template: 'fr fr fr';
    justify-content: space-around;
    position: absolute;
    width: 40%;
    top: 8px;
    border: none;
    right: 70px;
  }

  @media (max-width: 500px) {
    width: calc(100% - 70px);
  }
}

.help {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 150%;
  color: var(--header-font-color);
}
