.title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 24px;
}


.ttsBlock {
  display: flex;

  & .textareatts {
    //display: flex;
    resize: none;
    overflow: hidden;
    width: 750px;
    border-radius: 5px;
  }

  & .buttonsBlock {
    width: 215px;

    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    & .ttsSelect {
      margin: 0 10px;
      width: 300px;

    }
  }
}

input {
  border-radius: 5px;
}

textarea {
  display: flex;
  resize: none;
  overflow: hidden;
  min-width: 95%;
  min-height: 200px;
  border-radius: 5px;
  font-size: 18px;
  font-family: 'PF BeauSans Pro';

  padding: 10px;
}

.playerMain {
  width: 990px;

  position: absolute;
  margin: -172px 0;

  display: flex;
  align-items: center;
}

.buttonPlay {
  //position: relative;
  z-index: 1;
  //font-size: calc(4px + 2vmin);
  //margin-top: -45px;
  //margin-right: 20px;
  width: 30px;
  padding: 4px 0 0 0;
  float: right;
  background: var(--orange-color);
  font-family: 'PF BeauSans Pro';
  color: #fff;
  border: unset;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px;

  &:hover {
    background: #323232;
    color: #fff;
    border: unset;
  }
}.buttonPlayUser {
   width: 30px;
   padding: 4px 0 0 0;
   background: var(--orange-color);
   //font-family: 'PF BeauSans Pro';
   color: #fff;
   border: unset;
   text-transform: uppercase;
   cursor: pointer;
   transition: all 0.3s ease;
   border-radius: 5px;
   position: relative;
   margin-top: -28px;
   margin-left: 55px;
   float: right;
   //margin-bottom: 13px;

  &:hover {
    background: #323232;
    color: #fff;
    border: unset;
  }
}

.buttonStop {
  color: white;
}

.green {
  background-color: var(--green-color);
}

.player  {
  //position: relative;
  //margin-top: -60px;

  //width: 650px;
  width: calc(100% - 20px);
}

.w {
  width: 200px;
}

.button_example {
  //display: block;
  position: relative;
  //margin-left: 10px;
  margin-right: 100px;
  margin-top: auto;
  float: right;
}

.formBlock {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  margin: 24px 0;

  @media (max-width: 960px) {
    padding-left: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

}


.selectAllButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-right: 55px;

  & > :first-child {
    margin-right: 25px;
  }
}

.formBlockReq {
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  margin: 20px 0;

  @media (max-width: 960px) {
    padding-left: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & input {
    height: 35px;
    padding-left: 10px;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

  }
}

.formBlockT {
  display: flex;
  justify-content: space-between;
  //padding-right: 40px;
  float: right;
  margin: 4px 20px;

  @media (max-width: 960px) {
    padding-left: 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.blockTitle {
  flex: 1 1 60%;
  padding-right: 24px;
}

.subTitle {
  padding-left: 20px;
  font-size: 24px;
  min-width: 550px;

  @media (max-width: 960px) {
    padding-left: 0;
  }
}

.defaultInputReadOnly {
  flex: 0 0 40%;
  font-family: 'PF BeauSans Pro';
  max-width: 200px;
  font-size: 16px;
  background: transparent;
  border: 1px solid var(--border-default);
  padding: 9px 24px;
  border-radius: 5px;
  color: var(--light-color);
  outline: none;

  &.error {
    color: red;
  }

  @media (max-width: 600px) {
    margin-top: 24px;
  }

  &.noPadding {
    padding: 0 24px !important;
    cursor: pointer;
  }
}

.defaultInput {
  //flex: 0 0 40%;
  font-family: 'PF BeauSans Pro';
  max-width: 350px;
  font-size: 16px;
  background: transparent;
  border: 1px dotted var(--main-font-color);
  padding: 12px 22px;
  border-radius: 5px;
  font-weight: bold;
  color: var(--main-font-color);

  &.error {
    color: red;
  }

  @media (max-width: 600px) {
    margin-top: 24px;
  }

  &.noPadding {
    padding: 0 24px !important;
    cursor: pointer;
  }
}

.menuBurger {
  margin-top: 5px;
  img {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 961px) {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.floatR {
  float: right;
}

.button {
  padding: 4px 10px 6px;
  border-radius: 10px;
  background: var(--orange-color);
  font-family: 'PF BeauSans Pro';
  color: #fff;
  border: unset;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (min-width: 960px) {
    margin-left: 20px;
    font-size: 16px;
  }

  &:hover {
    background: #323232;
    color: #fff;
    border: unset;
  }
}

.realCheckBox {
  display: none;
}

.fakeCb {
  position: relative;
  width: 46px;
  height: 24px !important;

  background-color: #ebebeb;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dbdbdb;
  border-radius: 18px;

}

.fakeCb::before {
  content: "";

  width: 24px !important;
  height: 24px !important;
  margin: -1px 0 0 -1px;

  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 18px;

  transition: 0.2s ease-in;
  float: left;
}

.realCheckBox:checked + .fakeCb::before {
  float: right;
  background-color: var(--blue-color);
  opacity: 0.5;
}

.blockquote{
  font-size: 0.8em;
  font-style: italic;
  width:60%;
  margin:0 12px 0;
  color: #555555;
  padding:10px 30px 10px 75px;
  border-left:4px solid #c1bdbd;
  position: relative;
  background:#EDEDED;
}


// После мерджа разделов магазина с мэйном нужно перенести в основной попап
.greenBG {
  background-color: var(--green-color);
}

.yesNoStyle {
  font-size: 18px;

  & > * {
    margin: 0 10px;
  }
}
