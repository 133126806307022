.headBlock {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;

    & > * {
        margin-right: 25px;
    }

    & > :last-child {
        margin-right: 0;
    }

}

.filterCards {
    display: flex;

    & .filterCard {
        display: flex;
        align-items: center;

        width: 150px;

        cursor: pointer;
        padding: 25px;
        margin: 0 10px;

        box-sizing: border-box;
        border-radius: 10px;
        border: 1px solid var(--border-default);
    }

    & .filterCard:hover {
        background-color: var(--green-color);
        color: white;
        transition: all 0.3s ease;
    }

    & .selectedCard {
        background-color: var(--orange-color);
        color: white;
    }
}


.csvLink {
    margin-left: 20px;
}

.btn {
    padding: 4px 10px 6px;
    background: var(--orange-color);
    color: #fff;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    min-width: auto;
    cursor: pointer;
    font-family: 'PF BeauSans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    transition: all 0.3s ease;

    &:hover {
        background: #323232;
        color: #fff;
    }

    @media (max-width: 450px) {
        margin-bottom: 24px;
    }
}

.btnDownload {
    float: right;
    background-color: var(--green-color);
}

.SvgElem {
    font-size: 1em;
    vertical-align: middle;
}

.searchButton {
    margin: 0 24px;
    cursor: pointer;
    padding-bottom: 1px;

    &:hover {
        padding-bottom: 0;
        border-bottom: 1px dashed var(--blue-color);
        color: var(--blue-color)
    }
}

.activeSearch {
    padding-bottom: 0;
    border-bottom: 1px dashed var(--blue-color);
    color: var(--blue-color)
}

.searchInput {
    height: 50px;
    display: flex;
    align-items: center;

    & > * {
        margin: 0 10px;
    }

    label span {
        margin: 0 10px;
    }
}