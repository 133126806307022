@import './fonts/font-css.css';
@import './variables.scss';

body {
  background: linear-gradient(
    61deg,
    var(--background-gradient-end),
    var(--background-gradient-st)
  );
  margin: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.App {
  max-width: 1320px;
  //max-width: 98%;
  height: auto;
  margin: 0 auto;
  padding: 0 15px;
}

.full-width {
  max-width: 100%;
}


.servicesSelect {
  display: flex;
  text-align: center;
  vertical-align: middle;

  .servicesElem {
    margin: auto;
    width: 350px;
    height: 200px;

    cursor: pointer;

  }

  .line {
    line-height: 200px;
  }

  .noLine {

  }

  p {
    padding-top: 70px;
  }

  .servicesElem:hover {
    background-color: var(--blue-color);
    color: white;
    transition: all 0.2s linear;
  }

  .noLine:hover {
    border-radius: 20px 0 0 20px;
  }

  .line:hover {
    border-radius: 0 20px 20px 0;
  }
}

.white-button {
  font-family: 'PF BeauSans Pro';
  background: #fff;
  border: 1px solid var(--border-default);
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  padding: 12px 0 12px 0;
  transition: all 0.2s linear;
  vertical-align: top;

  width: 280px;
  text-align: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

.white-button:hover {
  background-color: #00a1e3 !important;
  color:white;
}


html {
  scroll-behavior: smooth;
}