.wrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.paragraph {
  white-space: nowrap;
}

.select {
  position: absolute;
  right: -18px;
  top: 35px;
  background: var(--background-gradient-st);
  z-index: 17;
  outline: none;
  border: 1px solid var(--border-default);
  margin: 0;
  margin-right: 12px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 10px;
  overflow-y: scroll;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 6px;
  display: none;

  &::-webkit-scrollbar {
    height: 12px; /* высота для горизонтального скролла */
    width: 12px;
    background-color: transparent;
    border-radius: 9em;
    margin-top: 5px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-font-color);
    border-radius: 9em;
    margin-top: 5px;
  }
}

.select > option {
  background: none;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  color: var(--main-font-color);
  width: 100px;
  overflow-y: scroll;
  font-family: 'PF BeauSans Pro';

  &:hover {
    color: var(--background-gradient-st);
    background: var(--main-font-color);
  }

  &:focus {
    color: var(--background-gradient-st);
    background: var(--main-font-color);
  }
}

.active {
  display: block;
}

.value {
  border: 1px solid var(--main-font-color);
  padding: 4px;
  border-radius: 5px;
  min-width: 50px;
  text-align: center;
}

.relative {
  position: relative;
}
