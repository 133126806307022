.cashWrapper {
  border: 1px solid var(--cash-border);
  box-sizing: border-box;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    61deg,
    var(--balance-background-st),
    var(--balance-background-end)
  );
  height: 100%;
  & > * {
    margin: 0;
  }
}

.cashHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 15%;

  @media (max-width: 1060px) {
    flex-direction: column;
    align-items: start;
  }
}

.paragraph {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5rem;
  color: var(--main-font-color);
  width: 95%;
  margin: 22px auto 8px;
  flex: 1 1 auto;
  max-height: 70px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 12px; /* высота для горизонтального скролла */
    background-color: var(--balance-card-background-st);
    border-radius: 9em;
    margin-top: 5px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-font-color);
    border-radius: 9em;
    margin-top: 5px;
  }

  @media (max-width: 1060px) {
    margin: 24px 0 24px;
  }
}

.cashTitle {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 25.89px;
  line-height: 31px;
  color: var(--main-font-color);
  margin: 0;
}

.cashAmount {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5rem;
  text-transform: uppercase;
  padding: 6px 10px;
  background: #ffb500;
  border-radius: 10px;
  white-space: nowrap;

  @media (max-width: 1060px) {
    margin-top: 10px;
  }
}

.button {
  font-family: 'PF BeauSans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5rem;
  text-transform: uppercase;
  padding: 6px 10px;
  border-radius: 10px;
  background: var(--blue-color);
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  flex: 0 0 16px;
  max-width: 370px;
  box-sizing: border-box;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    background: #fff;
    color: var(--blue-color);
    border: 1px solid var(--blue-color);
  }
}

.flexCash {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  p {
    margin: 10px 0 0;
  }
}
