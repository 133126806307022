.date {
  display: flex;
  position: relative;
  justify-content: space-around;
  min-height: 100px;
  max-width: 400px;
  color: var(--main-font-color);
  border-radius: 20px;
  padding: 12px 24px;
  box-sizing: border-box;
  background: var(--balance-card-background-st);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;

  & > div:first-child {
    margin-right: 25px;
  }

  @media (max-width: 1200px) {
    width: 90%;

    & > *:first-child {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 960px) {
    display: flex;
    position: relative;
    justify-content: space-around;
    width: 320px;
  }

  @media (max-width: 740px) {
    width: 90%;

    & > *:first-child {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 14px;
    width: 100%;
  }
}

.dateSmall {
  min-height: 35px;
  max-width: 100%;

  justify-content: flex-start;
}

.dateTitleSmall {
  padding: 9px;
}

.inlineBlock {
  display: flex;
  align-items: center;

  & p {
    height: 25px;
    line-height: 25px;
  }
}

.chooseDate {
  padding: 9px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  font-family: 'PF BeauSans Pro';
  cursor: text;
  line-height: normal;
}

.dateTitle {
  margin-bottom: 10px;
  color: var(--main-font-color);
}

.calendar {
  position: absolute;
  background: var(--balance-card-background-end) !important;
  max-width: 500px !important;
  top: 110%;
  left: 0;
  z-index: 15;

  @media (max-width: 550px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.fullWidth {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.help {
  $size: 17px;
  --size: #{$size};

  width: var(--size) !important;
  height: var(--size) !important;
  font-size: 12px;

  & > div {
    width: var(--size) !important;
    height: var(--size) !important;

    & > p {
      width: calc(var(--size) - 5px) !important;
      height: var(--size) !important;
      padding: 0;
      padding-left: 5px;
      line-height: 1.5;

      position: absolute;
      margin-left: 2px;
    }
    border: none;
  }
}