.flex {
  display: flex;

}

.buble {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 40px;

  .sectionLabel {
    font-weight: bold;
  }

  & > div {
    padding: 20px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;



    margin-right: 20px;
  }

  & > .menuBlock {
    min-width: 250px;
    max-width: 300px;
  }

  & > div:last-child {
    width: 100%;
  }

  & .sectionButton {
    margin: 10px 0;
    cursor: pointer;
  }

  & .activeSection {
    color: var(--orange-color);

  }

  & .sectionButton:hover {
    color: var(--orange-color);

  }
}

.content {
  margin-top: 20px;
  margin-bottom: 40px;
  max-width: 700px;

  & .featureText {
    margin: 10px 0;
  }

  & .formRow {
    display: flex;
    justify-content: left;
    margin: 10px 0;

    & div {
      text-align: left;
      max-width: 140px;
    }

    & :nth-child(1) {
      min-width: 200px;
    }

    .errorButton {
      background-color: rgba(255, 0, 0, 0.2);
      color: red;
    }
  }

  & input {
    border-radius: 5px;
    line-height: 25px;
    width: 350px;
  }

  & textarea {
    border-radius: 5px;
    width: 550px;
    height: 200px;
    overflow-y: auto;
    resize: none;
  }

  & .editButtons {
    margin: 20px 0;
    & > a {
      margin-right: 25px;
    }
  }

  & .deleteButton {
    width: 60px;
    padding: 5px;
    border-radius: 5px;

    font-size: 16px;
    cursor: pointer;
  }

  & .deleteButton:hover {
    background-color: var(--orange-color);
    color: white;
  }
}