.subSelector {
  border-bottom: none;
  fill: rgba(0, 0, 0, 0.6);
  color: red !important;

}

//.subSelector:hover {
//  cursor: pointer;
//  background-color: inherit;
//  box-shadow: none;
//  fill: var(--orange-color)
//}
//
//.test {
//  color: red;
//}

.main {
  color: var(--green-color)
}