.button {
    padding: 4px 10px 6px;
    background: var(--orange-color);
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    cursor: pointer;


&:hover {
     background: #323232 !important;
     color: #fff !important;
     border-color: #323232 !important;
 }
}

.popupHelp {
&:hover::after {
     content: attr(data-title);
     position: absolute !important; /* Абсолютное позиционирование */
 //left: 20%; top: 30%; /* Положение подсказки */
     margin-left: 10px !important;
     margin-top: 20px !important;
     z-index:999 !important; /* Отображаем подсказку поверх других элементов */
     background: var(--background-gradient-st) !important; /* Полупрозрачный цвет фона */
     color: var(--main-font-color) !important;

     border: 1px solid var(--border-default) !important;
     border-radius: 10px !important;
     padding: 5px 5px !important;

     font-size: 12px !important;
     text-transform: none !important;
     font-weight: bold !important;
 }
}

.popupHelpDwnld {
&:hover::after {
     content: attr(data-title);
     position: absolute !important; /* Абсолютное позиционирование */
 //left: 20%; top: 30%; /* Положение подсказки */
 //margin-left: 110px !important;
     margin-top: 25px !important;
     z-index:999 !important; /* Отображаем подсказку поверх других элементов */
     background: var(--background-gradient-st) !important; /* Полупрозрачный цвет фона */
     color: var(--main-font-color) !important;

     border: 1px solid var(--border-default) !important;
     border-radius: 10px !important;
     padding: 5px 5px !important;

     font-size: 12px !important;
     text-transform: none !important;
     font-weight: bold !important;
 }
}

.noButton {
    border: none;
    font-family: PF BeauSans Pro!important;
    font-size: 16px;
    height: 32px;
    padding: 2px 6px;
    line-height: 32px;

    appearance: auto;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: buttonface;
    margin: 0em;
    padding: 1px 6px;
    border-image: initial;
}

.lightButton {
&:hover {
     background: var(--blue-color) !important;
 }
}

.smallButton {
    padding: 0 10px !important;
    font-size: 12px;
}

.wideB {
    height: 53px;
    line-height: 53px;
}

.orangeButton {
    min-width: 200px;
    cursor: pointer;

    background-color: var(--orange-color);
    border-color: var(--orange-color);

    margin-top: 10px;
    margin-bottom: 10px;

    text-transform: uppercase;
    color: #fff;
    font-size: 1rem;
    border-radius: 0.5rem;
    font-weight: 700;
    overflow-wrap: break-spaces;

    touch-action: manipulation;
    transition: all 0.3s ease;
    transform: translate3d(0, 0, 0);

&:hover {
     background-color: black !important;
 }
}

.animated {

&:hover {
     background-color: black;
     margin-top: 5px;
     margin-bottom: 15px;
 }
}

.blueButton {
    background-color: var(--blue-color) !important;
    border-color: var(--blue-color) !important;
}

.greenButton {
    background-color: var(--green-color) !important;
    border-color: var(--green-color) !important;
}

.orangeButtonColor {
    background-color: var(--orange-color) !important;
    border-color: var(--orange-color) !important;
}

.noActiveButton {
    background-color: var(--border-default) !important;
    border-color: white !important;

    cursor: default;

&:hover {
     background-color: var(--border-default) !important;
 }

}

.btn {
    padding: 4px 10px 6px;
    background: var(--orange-color);
    color: #fff;
    border: none;
    border-radius: 10px;
    text-transform: uppercase;
    text-align: center;
    min-width: auto;
    cursor: pointer;
    font-family: 'PF BeauSans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    transition: all 0.3s ease;

&:hover {
     background: #323232;
     color: #fff;
 }

@media (max-width: 450px) {
    margin-bottom: 24px;
}
}

.btnDownload {
    font-size: 14px !important;
    font-weight: normal !important;
}

.SvgElem {
    font-size: 16px;
    vertical-align: middle;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SvgElem {
    font-size: 16px;
    vertical-align: middle;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonsTop {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 24px;

& > * {
      margin: 0 5px;
  }
}

.formTableM {
td, th {
    border: 1px solid var(--border-default);
    padding: 5px 5px;
}

th {
    text-align: center;
}
}

.csvLinkBig {
    width: 100px !important;
    height: 50px !important;
    font-size: 24px !important;

.SvgElem {
    font-size: 50px!important;
    vertical-align: middle;
}
}

.csvLink {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    height: 28px;
    background-color: var(--green-color);

    border: none;
    border-radius: 10px;

    color: #fff;
    font-size: 24px;

    cursor: pointer;

&:hover {
     background: #323232 !important;
     color: #fff !important;
 }
}