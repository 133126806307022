.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1250px) {
    flex-wrap: wrap;
  }

  & > * {
    flex: 0 0 calc(25% - 24px);
    padding: 12px 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 24px 0 0;
    //margin-left: 24px;

    @media (max-width: 1120px) {
      flex: 0 0 calc(50% - 24px);
      margin-bottom: 0;
    }

    @media (max-width: 550px) {
      flex: 0 0 100%;
      margin-left: 0;
    }
  }

  & > * {
    border: 1px solid var(--border-default);
    border-radius: 20px;

    &:hover {
      box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.07),
        0px 6.63236px 24.1177px rgba(0, 0, 0, 0.0456112),
        0px 2.75474px 10.0172px rgba(0, 0, 0, 0.035),
        0px 0.996336px 3.62304px rgba(0, 0, 0, 0.0243888);
    }
  }
}

.additionalDescription {
  font-size: 12px;

  margin: 25px 0;
}