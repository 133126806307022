.feature {
  color: var(--main-font-color);
}

.flex {
  display: flex;
  align-items: flex-start;
}

.tableBody > * {
  width: 100px;
  padding: 5px 10px;
  word-break: break-all;

  @media (max-width: 1400px) {
    width: 110px;
  }
}

.tableBody:hover {
  background-color: #3e98c7 !important;
}

.tableBody {
  display: block;
  padding: 5px 10px;
  cursor: pointer;

  td {
    height: 34px;
    font-size: 12px;
  }

}

.tableExtend {
  border-top: 1px solid var(--light-border);
  width: 100%;
  background-color: var(--background-gradient-st);
  cursor: auto;
  padding: 0 0;



  .sub {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .extendElem {

      width: 250px;
      padding: 5px 0;
      margin: 10px 40px;
      border: 1px solid var(--light-border);
      border-radius: 5px;


      .elemTitle {
        font-weight: bold;


      }

      .elem {

      }

      .subElem {
        display: block;
      }

    }

  }


}

