.title {
  font-size: 20px;
}

.text {
  font-size: 16px;

  & > span {
    font-weight: bold;
  }
}

.ruble {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
