.none {
  display: none;
}

.select {
  position: absolute;
  top: 40px;
  width: 100%;
  background: var(--background-gradient-st);
  z-index: 17;
  outline: none;
  border: 1px solid var(--border-default);
  height: 150px;
  margin: 0;
  margin-right: 12px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 10px;
  overflow-y: scroll;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 6px;

  &::-webkit-scrollbar {
    height: 12px; /* высота для горизонтального скролла */
    width: 12px;
    background-color: transparent;
    border-radius: 9em;
    margin-top: 5px;
  }

  /* ползунок скроллбара */
  &::-webkit-scrollbar-thumb {
    background-color: var(--main-font-color);
    border-radius: 9em;
    margin-top: 5px;
  }

  & > option {
    background: none;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    color: var(--main-font-color);

    &:hover {
      color: var(--background-gradient-st);
      background: var(--main-font-color);
    }

    &:focus {
      color: var(--background-gradient-st);
      background: var(--main-font-color);
    }
  }
}
