.mainPart {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 40px;

  & > *:first-child {
    flex: 0 0 calc(20.127% - 24px);
    margin-top: -24px;
  }

  & > *:last-child {
    flex: 0 0 79.5%;
    padding-left: 20px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 20px;

    @media (max-width: 992px) {
      flex: 0 0 100%;
    }
  }

  & .filterListBox {
    margin: 5px 25px;

    & > div {
      display: inline-block;
    }

    & .filterListBoxElem {


      & > div {
        background: var(--blue-color);
        border-radius: 5px;
        color: white;
        cursor: pointer;

        padding: 5px 10px;
        margin: 0 5px;
        display: inline-block;
      }

      & > div:hover {
        background: var(--orange-color);
      }
    }

  }

}

.minimalDesign {
  & > *:first-child {
    flex: 0 0 24px !important;
  }

  & > *:last-child {
    flex: 0 0 93% !important;
  }
}

.flex {
  display: flex;
  flex-direction: column;

  color: var(--main-font-color);
  padding: 24px;
}

.loadingDiv {
  position: absolute;
  margin-left: 50%;
}

.counterBlock {
  margin: 5px 10px;
  font-size: 16px;
  font-style: italic;
  color: var(--night-color);
}