.amoButtonHolder {
    position: fixed;
    z-index: 100000;
    right: 90px;
    bottom: 90px;

    padding: 7px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    & .amoButton {
        width: 60px;
        height: 60px;
        z-index: 100002;
        background-color: rgb(0, 140, 255);
        border-radius: 35px;
        float: right;
        opacity: 1;
    }

    & .amoButtonMain {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    & .amoButtonMainIon {
        & > svg:not(:root) {
            overflow: hidden;
        }
    }
}

.amo_animation_waves::before {
    animation-delay: 0s;
}


.amo_animation_waves::before,
.amo_animation_waves::after {
    content: "";
    background-color: inherit;
    display: block;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    border-radius: inherit;
    z-index: 0;
    animation: amo-button-wave infinite 2.6s 0s linear;
    left: 0;
}

.amoButtonCloseIcon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 32% !important;
    height: 32% !important;
}


.amo1 {
    position: fixed;
    z-index: 100000;
    right: 90px;
    bottom: 90px;
}

.amo2 {
    width: 60px; height: 60px; z-index: 100002; background-color: rgb(0, 140, 255); border-radius: 35px; float: right; opacity: 1;
}

.amo3 {
    height: 130px; width: 219px; display: none;
}

.amo4 {
    position: absolute; right: 18px; top: 18px; color: #fff; border-radius: 50%; background-color: rgba(0, 0, 0, 0.5); display: flex; align-items: center; justify-content: center; width: 18px; height: 18px; transition: all 0.3s ease; z-index: 1000;
}

.amo5 {
    width: min-content; left: 50%; transform: translateX(-50%); bottom: -18px;
}

.amo6 {
    position: absolute; width: 100%; height: 100%; top: 0; left: 0;
}

.masterHelpButton {
    position: fixed;
    z-index: 100000;

    bottom: 90px;
    right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;

    border-radius: 50%;
    background-color: var(--blue-color);
    content: "";
    cursor: pointer;

    animation: amo_animation_waves 2s 0.2s both infinite;

    & > svg {
        fill: var(--balance-card-background-light-st);
    }

    &:hover {
        background-color: var(--main-border-dark);
    }

    &:hover > svg {
        fill: var(--orange-color);
    }
}

@keyframes amo_animation_waves {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}

.mainMenu {
    min-width: 580px;
    min-height: 150px;

    max-width: 700px;

    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 1.8rem;

    display: flex;
    flex-direction: column;

    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(10px);
    //box-shadow: rgb(50 50 93 / 25%) 0 50px 100px -20px, rgb(0 0 0 / 30%) 0 30px 60px -30px;
    box-sizing: border-box;
    box-shadow: rgb(50, 50, 93, 0.25) 0 50px 100px -20px, rgb(0, 0, 0, 0.3) 0 30px 60px -30px;

    & > .menuTitle {
        margin-bottom: 10px !important;
        font-weight: 700;
        font-size: 1.8rem;

        display: flex;
        justify-content: space-between;
    }

    animation: income-block 1s 0.2s both;

}

.textMenu {
    cursor: pointer;

    padding: 5px 25px;

    &:hover {
        background-color: var(--blue-color);
        color: white;

        animation: text-hover 0.2s 0.1s both;
    }
}

.menuItemButton {
    text-align: right;

    &:hover {
        animation: text-hover-right 0.2s 0.1s both;
    }
}


.menuItem {
    width: 550px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    cursor: pointer;

    border-radius: 6px;
    padding: 11px 15px;
    font-size: 15px;

    background-color: var(--orange-color);
    border-color: var(--orange-color);
    text-transform: uppercase;
    color: #fff;

    font-weight: 700;
    line-height: 24px;
    vertical-align: middle;

    touch-action: manipulation;
    max-width: 100%;
    user-select: none;
    transition: all 0.3s ease;
    transform: translate3d(0, 0, 0);

    &:hover {
        background-color: black;
        color:  var(--balance-card-background-light-st);
        margin-top: 5px;
        margin-bottom: 15px;
    }
}

.flexRowButtons {
    display: flex;
    flex-direction: row;

    & > :first-child {
        margin-right: 10px;
    }

    & > :last-child {
        margin-left: 10px;
    }

    & > div {
        width: 50%;
    }
}

.closeButton {
    color: rgba(33, 33, 33, 1);
    margin-top: -2px;

    font-size: 34px;
    position: absolute;
    right: 1rem;
    top: 0;
    z-index: 1000;
    text-shadow: 0 1px 0#fff;
    opacity: 0.2;

    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.incomeAnimation {
    animation: income-block 0.5s 0.2s both;
}

.outcomeAnimation {
    animation: outcome-block 0.2s 0.2s both;
}

.showAnimation {
    animation: show-animation 1s 0.2s both;
}

.hideAnimation {
    animation: hide-animation 1.2s 0.2s both;
}

@keyframes income {
    0%,
    20%,
    50% {
        left: -2000px;
    }
    80% {
        left: 250px;
    }
    100% {
        left: 0;
    }
}

@keyframes income-block {
    0% {
        left: -100%;
    }
    100% {
        left: 50%;
    }
}

@keyframes text-hover {
    0% {
        padding-left: 25px;
    }
    100% {
        padding-left: 75px;
    }
}

@keyframes text-hover-right {
    0% {
        padding-right: 25px;
    }
    100% {
        padding-right: 75px;
    }
}

@keyframes outcome-block {
    0% {
        left: 50%;
    }
    100% {
        left: 140%;
    }
}

@keyframes show-animation {
    0% {
        display: none;
    }
    100% {
        display: block;
    }
}

@keyframes hide-animation {
    0% {
        display: block;
        background-color: rgba(99, 99, 100, 0.8);
    }
    100% {
        background-color: rgba(99, 99, 100, 0);
        display: none;
    }
}

.videoBlock {
    max-width: 700px;
}
