@font-face {
  font-family: 'PF BeauSans Pro';
  src: url('./PF-BeauSans-Pro/subset-PFBeauSansPro-Light.eot');
  src: url('./PF-BeauSans-Pro/subset-PFBeauSansPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('./PF-BeauSans-Pro/subset-PFBeauSansPro-Light.woff2') format('woff2'),
    url('./PF-BeauSans-Pro/subset-PFBeauSansPro-Light.woff') format('woff'),
    url('./PF-BeauSans-Pro/subset-PFBeauSansPro-Light.svg#PFBeauSansPro-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PF BeauSans Pro Semibold';
  src: url('./PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.eot');
  src: url('./PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.woff2')
      format('woff2'),
    url('./PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.woff')
      format('woff'),
    url('./PF-BeauSans-Pro-Semibold/subset-PFBeauSansPro-SemiBoldItalic.svg#PFBeauSansPro-SemiBoldItalic')
      format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
    font-family: 'PF BeauSans Pro Bold';
    src: url('./PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.eot');
    src: url('./PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.woff2') format('woff2'),
        url('./PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.woff') format('woff'),
        url('./PF-BeauSans-Pro-Bold/subset-PFBeauSansPro-Bold.svg#PFBeauSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


