.elemGroup {
  text-align: left;
  font-size: 16px;

  border-bottom: 1px dashed var(--border-default);

  .label {
    font-style: italic;
  }

  .labelInline {
    font-style: italic;
    display: inline-block;
    min-width: 410px;
  }

  .elemInline {
    display: inline-block;
    min-width: 300px;
    width: 16px;

  }

  .elemStatus {
    display: inline-block;
  }

  .elem {
    vertical-align: center;
    line-height: 25px;


    div {
      display: inline-block;
    }

    .elemTitle {
      display: inline-block;
      text-align: right;
      padding-right: 30px;
      min-width: 380px;
    }


  }
}

.successStatus {
  color: var(--green-color);
}

.failStatus {
  color: red;
}

.spinner {
  margin-bottom: -5px !important;
}

.spinnerMain {
  line-height: 16px;
}

.errorMessage {
  font-size: 20px;
  color: red;
}

.crossBut {
  cursor: pointer;
  color: var(--green-color)
}

.crossButNo {
  cursor: default;
  width: 60px;
}

.price {
  padding: 0 10px 0 10px;
  font-size: 12px;
}

.resultPrice {
  font-size: 20px;
  margin: 25px 0 25px 0;
  font-weight: bold;
  text-align: left;
}